import React, { useCallback } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Input,
  Button,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import { FaSearch } from "react-icons/fa";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";

const createDateObj = (val) => {
  let date = new Date(val?.created_at);
  if (isNaN(date.getTime()) && val?.created_at?.seconds) {
    date = new Date(val.created_at.seconds * 1000); // Convert seconds to milliseconds
  }
  if (isNaN(date.getTime())) {
    date = new Date();
  }
  return date;
};

const sortAndFilter = (things, order, searchText, providers) => {
  if (!things || !things.length) return [];
  let newArr = JSON.parse(JSON.stringify(things));
  const hasSearch = searchText && searchText.length;

  newArr = newArr.filter((thing) => {
    const isInProviders = providers[thing.goFinal_agent.toLowerCase()];
    const isInSearch = hasSearch
      ? thing.goFinal_name.toLowerCase().includes(searchText.toLowerCase()) ||
        thing.goFinal_agent.toLowerCase().includes(searchText.toLowerCase())
      : true;

    return isInProviders && isInSearch;
  });

  if (order === "newest") {
    return newArr.sort((a, b) => {
      return createDateObj(b) - createDateObj(a);
    });
  } else if (order === "oldest") {
    return newArr.sort((a, b) => {
      return createDateObj(a) - createDateObj(b);
    });
  } else if (order === "az") {
    return newArr.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  } else if (order === "za") {
    return newArr.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
  }

  return newArr;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
});

function ModelsComponent({ sort, providers, searchText, setSearchText }) {
  const styles = useStyles();
  const navigate = useNavigate();
  const userThings = useSelector((state) => state.likes.likesArr);
  const [things, setThings] = React.useState(userThings);
  const [loading, setLoading] = React.useState(true);

  const loadModels = useCallback(async () => {
    setLoading(true);
    setThings(sortAndFilter(userThings, sort, searchText, providers));
    setLoading(false);
  }, [providers, searchText, sort, userThings]);

  React.useEffect(() => {
    loadModels();
  }, [loadModels]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  const methods = React.useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const handleClosePress = React.useCallback(() => {
    setSearchText("");
  }, [setSearchText]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Input
          style={{
            color: "white",
            fontSize: "24px",
            width: "100%",
            backgroundColor: teamsDarkTheme.colorNeutralBackground2,
            ...shorthands.padding("10px", "20px"),
            ...shorthands.border(
              "1px",
              "solid",
              teamsDarkTheme.colorNeutralStroke1
            ),
            boxSizing: "border-box",
            ...shorthands.borderRadius("5px"),
          }}
          value={searchText}
          contentBefore={
            <FaSearch
              size={24}
              style={{ marginRight: "5px", padding: "5px 0px" }}
            />
          }
          contentAfter={
            searchText.length ? (
              <Button
                appearance="transparent"
                size="medium"
                icon={<Close />}
                onClick={handleClosePress}
              />
            ) : null
          }
          size="large"
          onChange={(e, data) => setSearchText(data.value)}
          appearance="underline"
          placeholder="Search Results"
        />
      </div>
      <div className={styles.resultsWrap}>
        {things && things.length ? (
          <>
            {things.map((thing, i) => {
              const normalThing =
                thing !== 0 && modelNormalizer(thing, thing.blendSource);
              return (
                <ThingCard
                  thing={normalThing}
                  agent={thing.blendSource}
                  loading={loading}
                  methods={methods}
                  key={i}
                />
              );
            })}
          </>
        ) : (
          "No Results Found..."
        )}
      </div>
    </div>
  );
}

export default React.memo(ModelsComponent);
