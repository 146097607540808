import React, { useEffect } from "react";
import {
  Button,
  Spinner,
  Text,
  Title2,
  Title3,
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Input,
} from "@fluentui/react-components";
import { Navigate, useLocation } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { HexColorPicker } from "react-colorful";
import { useSelector } from "react-redux";

const colorSet = {
  primary: "#5b5fc7",
  textPrimary: "#FFFFFF",
  textSecondary: "#FFFFFF",
  background: "#292929",
  secondary: "#3d3d3d",
  border: "transparent",
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
  },
  loaderWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("10px"),
    ...shorthands.flex(1),
  },
  resultsMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px"),
    // height: "100%",
    ...shorthands.flex(1),
  },
  text: {
    textAlign: "center",
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("5px", "10px"),
  },
  resultsUpper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.borderRadius("5px"),
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",
    ...shorthands.gap("10px"),
    flexGrow: 1,
  },
  footerLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("10px"),
  },
  footerRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
    ...shorthands.gap("10px"),
  },
  emptyWrap: {
    ...shorthands.padding("20px"),
    display: "flex",
    height: "100%",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("20px"),
  },
  examplesWrap: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    ...shorthands.gap("10px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("5px", "10px"),
  },
  clickables: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    ...shorthands.gap("10px"),
    ":hover": {
      cursor: "pointer",
    },
  },
  inputWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("10px", "15px"),
    ...shorthands.gap("10px"),
    width: "40%",
    minWidth: "300px",
  },
  input: {
    display: "flex",
    ...shorthands.flex(1),
  },
});

function ShapEScreen() {
  const styles = useStyles();
  const client = window.gradioClient;
  const [loading, setLoading] = React.useState(false);
  const [responseData, setResponseData] = React.useState(null);
  const revenueCat = useSelector((state) => state.user.revenueCat);
  const [color, setColor] = React.useState("transparent");
  const [prompt, setPrompt] = React.useState("");
  const [showColors, setShowColors] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const modelRef = React.useRef(null);

  const test = async (val) => {
    setLoading(true);
    const app = await client("https://hysts-shap-e.hf.space/--replicas/2lwpl/");
    const result = await app.predict("/load_example", [
      val, // undefined (index of selected example) in 'parameter_17' Dataset component
    ]);
    setPrompt(result.data[0]);
    setResponseData(result.data[1]);
    setLoading(false);
  };

  const newPrompt = React.useCallback(async () => {
    if (!prompt || !prompt.length) return;
    setLoading(true);
    const app = await client("https://hysts-shap-e.hf.space/--replicas/2lwpl/");
    const randomNumber = Math.floor(Math.random() * 2147483648);
    const result = await app.predict("/text-to-3d", [
      prompt, // string  in 'Prompt' Textbox component
      randomNumber, // number (numeric value between 0 and 2147483647) in 'Seed' Slider component
      20, // number (numeric value between 1 and 20) in 'Guidance scale' Slider component
      100, // number (numeric value between 2 and 100) in 'Number of inference steps' Slider component
    ]);
    // setPrompt(prompt);
    setResponseData(result.data[0]);
    setLoading(false);
  }, [client, prompt]);

  const handleColorToggle = () => {
    setShowColors(!showColors);
  };

  const handleDownload = async () => {
    setDownloading(true);
    window.open(
      "https://hysts-shap-e.hf.space/file=" + responseData.path,
      "_blank"
    );
    setDownloading(false);
  };

  // PAYWALL
  if (!revenueCat?.entitlements?.pro) {
    return <Navigate to="/models" />;
  }

  return (
    <div className={styles.main}>
      {loading ? (
        <div className={styles.loaderWrap}>
          <BarLoader color={colorSet.textPrimary} loading size={100} />
          <Text>(this should take 10-15 seconds)</Text>
        </div>
      ) : responseData ? (
        <div className={styles.resultsMain}>
          <Title2
            className={styles.text}
            truncate={true}
            style={{
              maxHeight: 80,
              color: colorSet.textPrimary,
              backgroundColor: colorSet.secondary,
              border: `1px solid ${colorSet.border}`,
              overflow: "hidden",
            }}
          >
            {prompt}
          </Title2>
          <div
            className={styles.resultsUpper}
            style={{
              backgroundColor: colorSet.secondary,
              border: `1px solid ${colorSet.border}`,
            }}
          >
            <Text
              className={styles.text}
              style={{ color: colorSet.textPrimary }}
            >
              Renders are very rough. I am actively looking for better solutions
              as they become available.
            </Text>
            <Text
              className={styles.text}
              style={{ color: colorSet.textPrimary }}
            >
              For now, have some fun with the AI 😊
            </Text>
          </div>
          <model-viewer
            camera-controls
            alt="A 3D model of an astronaut"
            src={"https://hysts-shap-e.hf.space/file=" + responseData.path}
            ref={modelRef}
            style={{
              width: "225px",
              height: "225px",
              backgroundColor: color,
              border: `1px solid lightgrey`,
              borderRadius: "10px",
            }}
          ></model-viewer>
          <div className={styles.footer}>
            <div className={styles.footerLeft}>
              {showColors ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <HexColorPicker
                    color={color}
                    onChange={setColor}
                    style={{ width: "125px", height: "125px" }}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                    onClick={handleColorToggle}
                  >
                    <Text
                      style={{
                        color: colorSet.textPrimary,
                      }}
                    >
                      Close
                    </Text>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={handleColorToggle}
                >
                  <div
                    style={{
                      width: "35px",
                      height: "15px",
                      backgroundColor: color,
                      border: `2px solid lightgrey`,
                      borderRadius: "10px",
                    }}
                  />
                  <Text
                    style={{
                      color: colorSet.textPrimary,
                      // backgroundColor: colorSet.secondary,
                      // border: `1px solid ${colorSet.border}`,
                      borderRadius: "5px",
                      padding: "5px 10px",
                    }}
                  >
                    Change Background
                  </Text>
                </div>
              )}
            </div>
            <div className={styles.footerRight}>
              <Button
                style={{ backgroundColor: colorSet.primary }}
                onClick={() => handleDownload()}
                icon={
                  downloading ? (
                    <Spinner size="extra-tiny" appearance={"primary"} />
                  ) : null
                }
              >
                Download
              </Button>
              <Button
                className={styles.clearBtn}
                onClick={() => {
                  setColor("transparent");
                  setResponseData(null);
                  setPrompt("");
                }}
                style={{
                  backgroundColor: colorSet.primary,
                  color: colorSet.textSecondary,
                }}
              >
                Clear Results
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.emptyWrap}>
          <Title3
            className={styles.text}
            style={{
              maxHeight: 80,
              color: colorSet.textPrimary,
              backgroundColor: colorSet.secondary,
              border: `1px solid ${colorSet.border}`,
            }}
          >
            AI Powered Text-to-3D (beta)
          </Title3>
          <Text
            className={styles.text}
            style={{
              color: colorSet.textPrimary,
              backgroundColor: colorSet.secondary,
              border: `1px solid ${colorSet.border}`,
            }}
          >
            Enter a prompt to generate a 3D model 🪄
          </Text>
          <div
            className={styles.examplesWrap}
            style={{
              backgroundColor: colorSet.secondary,
              border: `1px solid ${colorSet.border}`,
            }}
          >
            <Text style={{ color: colorSet.textPrimary }}>
              Try some examples:
            </Text>
            <div className={styles.clickables}>
              <Text
                className={styles.text}
                style={{
                  color: colorSet.textSecondary,
                  border: `1px solid ${colorSet.border}`,
                  backgroundColor: colorSet.primary,
                }}
                onClick={() => test(0)}
              >
                A chair that looks like an avocado
              </Text>
              <Text
                className={styles.text}
                style={{
                  color: colorSet.textSecondary,
                  border: `1px solid ${colorSet.border}`,
                  backgroundColor: colorSet.primary,
                }}
                onClick={() => test(1)}
              >
                An airplane that looks like a banana
              </Text>
              <Text
                className={styles.text}
                style={{
                  color: colorSet.textSecondary,
                  border: `1px solid ${colorSet.border}`,
                  backgroundColor: colorSet.primary,
                }}
                onClick={() => test(2)}
              >
                A spaceship
              </Text>
              <Text
                className={styles.text}
                style={{
                  color: colorSet.textSecondary,
                  border: `1px solid ${colorSet.border}`,
                  backgroundColor: colorSet.primary,
                }}
                onClick={() => test(3)}
              >
                A birthday cupcake
              </Text>
            </div>
          </div>
          <div
            className={styles.inputWrap}
            style={{
              backgroundColor: colorSet.secondary,
            }}
          >
            <Input
              placeholder="enter a prompt"
              className={styles.input}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <Button appearance="primary" onClick={newPrompt}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ShapEScreen);
