import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Button,
  Text,
  makeStyles,
  shorthands,
  teamsDarkTheme,
} from "@fluentui/react-components";
import { ChevronLeft20Filled } from "@fluentui/react-icons";
import { BarLoader } from "react-spinners";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  stripeWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: "#252525",
    ...shorthands.gap("20px"),
  },
  buttonWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    ...shorthands.gap("10px"),
  },
  backBtn: {
    display: "flex",
    // flexGrow: 1,
  },
  submitBtn: {
    display: "flex",
    flexGrow: 3,
  },
  loader: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  processingDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
  },
});

const Processor = ({ setShowCheckout, clientSecret, purchaseData }) => {
  const styles = useStyles();
  const [errors, setErrors] = useState("");
  const [processing, setProcessing] = useState(false);
  const user = useSelector((state) => state?.user?.value);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    if (!stripe || !clientSecret) {
      return;
    }
    setProcessing(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://3dgo.app/stripe-purchase?subscription_id=${purchaseData.subscriptionId}&userId=${user.uid}&email=${user.email}`,
        // return_url: `http://localhost:3000/stripe-purchase?subscription_id=${purchaseData.subscriptionId}&userId=${user.uid}&email=${user.email}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      result?.error.type === "card_error" ||
      result?.error.type === "validation_error"
    ) {
      setErrors(result?.error.message);
    } else {
      setErrors("An unexpected error occurred.");
    }

    setProcessing(false);
  };

  return (
    <div className={styles.stripeWrap}>
      {processing && (
        <div className={styles.processingDiv}>
          <BarLoader color="white" />
        </div>
      )}
      <PaymentElement />
      {errors && (
        <Text
          style={{
            color: teamsDarkTheme.colorStatusDangerForeground3,
          }}
        >
          {errors}
        </Text>
      )}
      <div className={styles.buttonWrap}>
        <Button
          className={styles.backBtn}
          icon={<ChevronLeft20Filled />}
          onClick={() => setShowCheckout(false)}
        />
        <Button
          className={styles.submitBtn}
          appearance="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Processor);
