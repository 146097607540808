import React, { useMemo } from "react";
import {
  makeStyles,
  Skeleton,
  SkeletonItem,
  teamsDarkTheme,
  Title3,
  shorthands,
  Button,
} from "@fluentui/react-components";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import parse from "html-react-parser";
import { ChevronDown20Filled, ChevronUp20Filled } from "@fluentui/react-icons";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

const useStyles = makeStyles({
  descriptionMain: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
  descriptionWrap: {
    display: "flex",
    maxWidth: "100%",
    flexDirection: "column",
    overflow: "hidden",
    "& *": {
      maxWidth: "100%",
      color: teamsDarkTheme.colorNeutralForeground1,
      lineHeight: "20px",
    },
  },
  expandedDiv: {
    overflow: "scroll",
    overflowX: "hidden",
    height: "auto",
    maxHeight: "100%",
    minHeight: "120px",
  },
  closedDiv: {
    height: "120px",
    maxHeight: "120px",
    overflowY: "hidden",
  },
  skeletonText: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    ...shorthands.gap("10px"),
  },
});

const modifyDescription = (htmlString, agent) => {
  if (!htmlString.includes("<img")) {
    return htmlString;
  }

  if (agent === "printables") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.querySelectorAll("img");
    images.forEach((img) => {
      img.src = modifySrc(img.src);
    });
    // Convert the modified HTML back to string
    return doc.body.innerHTML;
  }

  return htmlString;
};

const modifySrc = (src) => {
  if (src.startsWith(proxyUrl)) {
    return src;
  }
  return proxyUrl + src;
};

function ModelDescription({ selectedThing, LoadingThing, thingDetails }) {
  const styles = useStyles();
  const agent = selectedThing.goFinal_agent;
  const [descHeight, setDescHeight] = React.useState("auto");

  const handleToggleDesc = React.useCallback(() => {
    setDescHeight(descHeight === "auto" ? "min" : "auto");
  }, [descHeight]);

  return (
    <div className={styles.descriptionMain}>
      <Title3>Description</Title3>
      <div
        className={
          descHeight !== "auto" ? styles.expandedDiv : styles.closedDiv
        }
      >
        {agent === "thingiverse" ||
        agent === "printables" ||
        agent === "makerworld" ? (
          <>
            {LoadingThing ? (
              <Skeleton className={styles.skeletonText}>
                <SkeletonItem size={16} shape="rectangle" />
                <SkeletonItem size={16} shape="rectangle" />
                <SkeletonItem size={16} shape="rectangle" />
              </Skeleton>
            ) : (
              <div className={styles.descriptionWrap}>
                {parse(
                  modifyDescription(
                    `<div>${thingDetails.description}</div>`,
                    agent
                  )
                )}
              </div>
            )}
          </>
        ) : (
          <div className={styles.descriptionWrap}>
            {parse(
              modifyDescription(
                `<div>${selectedThing.goFinal_description}</div>`,
                agent
              )
            )}
          </div>
        )}
      </div>
      <Button
        onClick={handleToggleDesc}
        icon={
          descHeight === "auto" ? (
            <ChevronDown20Filled />
          ) : (
            <ChevronUp20Filled />
          )
        }
      >
        {descHeight === "auto" ? "show more" : "show less"}
      </Button>
    </div>
  );
}

export default React.memo(ModelDescription);
