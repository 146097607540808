import React, { useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
} from "@fluentui/react-components";
import {
  BsChevronBarDown,
  BsChevronBarRight,
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";

const useStyles = makeStyles({
  footer: {
    ...shorthands.borderTop("solid", "1px", teamsDarkTheme.colorNeutralStroke1),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
    ...shorthands.padding("0px", "15px"),
  },
  footHeader: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("10px"),
    justifyContent: "space-between",
  },
});

function HomeFooter() {
  const styles = useStyles();

  const [showContent, setShowContent] = useState(() => {
    const defaultVal = localStorage.getItem("showHomeContent");
    if (defaultVal) {
      return JSON.parse(defaultVal);
    } else {
      return true;
    }
  });

  useEffect(() => {
    localStorage.setItem("showHomeContent", JSON.stringify(showContent));
  }, [showContent]);

  const headingObj = useMemo(() => {
    if (showContent) {
      return {
        text: "Hide",
        icon: <BsChevronDown />,
      };
    } else {
      return {
        text: "Expand",
        icon: <BsChevronLeft />,
      };
    }
  }, [showContent]);

  return (
    <div className={styles.footer}>
      <div className={styles.footHeader}>
        <h1>Unlock Your Creativity: Free 3D Printer Files</h1>
        <Button
          appearance="outline"
          style={{ borderRadius: 10 }}
          icon={headingObj.icon}
          onClick={() => setShowContent((v) => !v)}
        >
          {headingObj.text}
        </Button>
      </div>
      {showContent && (
        <>
          <p>
            In the realm of 3D printing, the availability of free resources is
            like a golden ticket to a world of limitless possibilities. One such
            treasure trove is the realm of free 3D printer files. These digital
            blueprints, also known as STL files, serve as the building blocks
            for bringing your wildest ideas to life, layer by layer, without
            costing you a dime.
          </p>
          <h2>Exploring the World of Free 3D Printer Files</h2>
          <p>
            Whether you're a hobbyist tinkering in your garage or a seasoned
            professional pushing the boundaries of innovation, the allure of
            free STL files for 3D printing is undeniable. These digital marvels
            hold the key to a realm where imagination knows no bounds and
            creativity reigns supreme. From intricate sculptures to functional
            prototypes, the possibilities are as vast as the cosmos itself.
          </p>
          <h2>Unlocking the Power of Free STL Files for 3D Printing</h2>
          <p>
            Imagine the thrill of accessing a vast library of free STL model
            files at your fingertips, each one waiting to be transformed into a
            tangible masterpiece. Whether you're a novice seeking to dip your
            toes into the waters of 3D printing or a seasoned veteran on the
            quest for your next big project, the journey begins with a single
            click.
          </p>
          <h3>Where Free 3D Printer Files Fuel Inspiration</h3>
          <p>
            At the heart of the 3D printing revolution lies a community fueled
            by passion, ingenuity, and a shared love for creation. With free STL
            files for 3D printing paving the way, enthusiasts from all walks of
            life come together to push the boundaries of what's possible.
            Whether it's tinkering with 3D printer models to create personalized
            gifts or prototyping new inventions, the journey is as rewarding as
            the destination.
          </p>
          <h3>
            Empowering Communities Through Collaboration: Harnessing the
            Potential of Free 3D Printer Files
          </h3>
          <p>
            Beyond the confines of individual pursuits, the true magic of free
            3D printer files lies in their ability to foster collaboration and
            drive positive change. From grassroots initiatives to global
            movements, the accessibility of these files enables communities to
            come together in pursuit of a common goal. Whether it's designing
            prosthetic limbs for those in need or creating eco-friendly
            solutions to combat pollution, the impact is nothing short of
            transformative.
          </p>
          <h3>
            Dive Into the World of Free 3D Printer Files and Unleash Your
            Creativity
          </h3>
          <p>
            As we journey through the realm of free 3D printer files, I invite
            you to join the revolution and unleash your creativity like never
            before. Whether you're a dreamer, a doer, or simply curious to see
            where the path may lead, there's never been a better time to dive in
            and explore. With each download, you're not just acquiring a file;
            you're embarking on a voyage of discovery that has the power to
            change the world—one layer at a time.
          </p>
          <h2>The Impact of Free 3D Printer Files on Innovation</h2>
          <p>
            The democratization of design through free 3D printer files has
            revolutionized the way we approach innovation. No longer bound by
            the constraints of traditional manufacturing processes or exorbitant
            design fees, individuals and businesses alike are empowered to bring
            their ideas to life with unprecedented speed and ease. Whether it's
            a small-scale project or a groundbreaking invention, the
            accessibility of these files has leveled the playing field, allowing
            creativity to flourish in ways previously thought impossible.
          </p>
          <h3>Free 3D Printer Files as a Tool for Learning</h3>
          <p>
            In addition to fueling innovation, free 3D printer files have become
            invaluable tools for education and skill development. From
            classrooms to makerspaces, educators are harnessing the power of 3D
            printing to engage students in hands-on learning experiences that
            foster creativity, critical thinking, and problem-solving skills. By
            providing access to free STL files for 3D printing, educators can
            empower students to explore complex concepts in science, technology,
            engineering, and mathematics (STEM) in a tangible and interactive
            way.
          </p>
          <h3>
            The Role of Free 3D Printer Files in Environmental Conservation
          </h3>
          <p>
            As we strive to build a more sustainable future, free 3D printer
            files have emerged as powerful tools for environmental conservation.
            By enabling the creation of customized, on-demand products, 3D
            printing reduces waste and minimizes the environmental impact
            associated with traditional manufacturing processes. From recycled
            materials to renewable energy solutions, the possibilities for
            leveraging 3D printing to address environmental challenges are
            virtually limitless. With free access to STL files for 3D printing,
            individuals and organizations can collaborate to develop innovative
            solutions that protect our planet for generations to come.
          </p>
          <h2>
            Join the Movement: Embrace the Power of Free 3D Printer Files Today
          </h2>
          <p>
            The era of free 3D printer files represents a paradigm shift in the
            way we create, learn, and innovate. Whether you're a designer,
            educator, entrepreneur, or simply an enthusiast eager to explore the
            possibilities of 3D printing, there has never been a better time to
            join the movement. With each download, you're not just acquiring a
            file; you're becoming part of a global community united by a shared
            passion for creativity and exploration. So what are you waiting for?
            Dive into the world of free 3D printer files today and unleash your
            imagination like never before.
          </p>
        </>
      )}
    </div>
  );
}

export default React.memo(HomeFooter);
