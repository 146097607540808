import {
  Button,
  Divider,
  Image,
  Text,
  Title2,
  makeStyles,
  shorthands,
  teamsDarkTheme,
} from "@fluentui/react-components";
import React from "react";
import AgentIcon from "../../AgentIcon/AgentIcon";
import {
  Android,
  Apple,
  BookmarkAddRounded,
  Google,
  SearchOutlined,
} from "@mui/icons-material";
import { BsRobot } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import { MdCollectionsBookmark } from "react-icons/md";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../hooks/useFirebase";

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en&gl=US";
const APP_STORE_URL =
  "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";

const useStyles = makeStyles({
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.gap("10px"),
    ...shorthands.flex(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    width: "100%",
    height: "100%",
    "> *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
    "> div": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground1,
      boxShadow: teamsDarkTheme.shadow2,
      ...shorthands.padding("5px", "15px"),
      ...shorthands.borderRadius("5px"),
    },
    "> div > *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("5px"),
  },
  footerButton: {
    boxShadow: teamsDarkTheme.shadow4,
    ...shorthands.borderRadius("10px"),
    ...shorthands.padding("7px", "5px"),
    cursor: "pointer",
    zIndex: 100,
  },
  Hdots: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
  },
  activeDot: {
    width: "10px",
    height: "10px",
    ...shorthands.borderRadius("50%"),
    backgroundColor: teamsDarkTheme.colorBrandStroke1,
  },
  inactiveDot: {
    width: "10px",
    height: "10px",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    boxSizing: "border-box",
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
  },
  agentIconWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
  },
  image: {
    width: "250px",
    ...shorthands.borderRadius("5px"),
    boxShadow: teamsDarkTheme.shadow2,
    // height: "250px",
    display: "flex",
    // maxHeight: "250px",
  },
  statH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    ...shorthands.gap("10px"),
  },
});

const Tab2 = ({ swiperRef }) => {
  const styles = useStyles();
  const agentStyles = {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
  };

  const handleNext = () => {
    logEvent(analytics, "onboarding-tab2-next");
    swiperRef?.current?.swiper && swiperRef.current.swiper.slideNext();
  };

  const handleBack = () => {
    swiperRef?.current?.swiper && swiperRef.current.swiper.slidePrev();
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.statH}>
          <Title2>So Much More 🖤</Title2>
        </div>
        <Divider style={{ maxHeight: "1px", padding: "0px" }} />
        <div className={styles.statH}>
          <BookmarkAddRounded />
          <Text>Like & Save Models for Later</Text>
        </div>
        <div className={styles.statH}>
          <SearchOutlined />
          <Text>Filter & Search Across All Providers</Text>
        </div>
        <div className={styles.statH}>
          <MdCollectionsBookmark size={18} />
          <Text>Create & Search Collections</Text>
        </div>
        <Divider style={{ maxHeight: "1px", padding: "0px" }} />
        <div>
          <Text>
            📱 Enjoy All The Same Features In Our{" "}
            <Text
              onClick={() => window.open(PLAY_STORE_URL, "_blank")}
              style={{ fontWeight: "bold", cursor: "pointer" }}
              underline
            >
              Android
            </Text>{" "}
            and{" "}
            <Text
              onClick={() => window.open(APP_STORE_URL, "_blank")}
              style={{ fontWeight: "bold", cursor: "pointer" }}
              underline
            >
              iOS
            </Text>{" "}
            Mobile App
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
            backgroundColor: "transparent",
          }}
        >
          <Button
            style={{
              height: "100px",
            }}
            icon={<Apple />}
          >
            App Store
          </Button>
          <Button
            style={{
              height: "100px",
            }}
            icon={<Android />}
          >
            Google Play
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          appearance="primary"
          className={styles.footerButton}
          onClick={handleBack}
        >
          Back
        </Button>
        <div className={styles.Hdots}>
          <div className={styles.inactiveDot} />
          <div className={styles.activeDot} />
          <div className={styles.inactiveDot} />
          <div className={styles.inactiveDot} />
        </div>
        <Button
          appearance="primary"
          className={styles.footerButton}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Tab2);
