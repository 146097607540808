import {
  Button,
  Divider,
  Image,
  Text,
  Title2,
  Title3,
  makeStyles,
  shorthands,
  teamsDarkTheme,
} from "@fluentui/react-components";
import React from "react";
import AgentIcon from "../../AgentIcon/AgentIcon";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../hooks/useFirebase";

const useStyles = makeStyles({
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.gap("10px"),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    "> *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
    "> div": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground1,
      boxShadow: teamsDarkTheme.shadow2,
      ...shorthands.padding("5px", "15px"),
      ...shorthands.borderRadius("5px"),
    },
    "> div > *": {
      textAlign: "center",
      fontWeight: "bold",
      border: "none",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("5px"),
  },
  footerButton: {
    boxShadow: teamsDarkTheme.shadow4,
    ...shorthands.borderRadius("10px"),
    ...shorthands.padding("7px", "5px"),
    cursor: "pointer",
    zIndex: 100,
  },
  Hdots: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
  },
  activeDot: {
    width: "10px",
    height: "10px",
    ...shorthands.borderRadius("50%"),
    backgroundColor: teamsDarkTheme.colorBrandStroke1,
  },
  inactiveDot: {
    width: "10px",
    height: "10px",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    boxSizing: "border-box",
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
  },
  agentIconWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
  },
  gif: {
    width: "100%",
    maxHeight: "450px",
  },
});

const Tab3 = ({ swiperRef }) => {
  const styles = useStyles();
  const agentStyles = {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
  };

  const handleNext = () => {
    logEvent(analytics, "onboarding-tab3-next");
    swiperRef?.current?.swiper && swiperRef.current.swiper.slideNext();
  };

  const handleBack = () => {
    swiperRef?.current?.swiper && swiperRef.current.swiper.slidePrev();
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div>
          <Title3>Search, Slice & Print Ready</Title3>
        </div>
        <Divider style={{ maxHeight: "1px", padding: "0px" }} />
        <div>
          <Text>✂️ New Web & Mobile Slicer for Quick Printing</Text>
        </div>
        <Image
          src={require("./slicer.gif")}
          className={styles.gif}
          fit="contain"
        />
      </div>
      <div className={styles.footer}>
        <Button
          appearance="primary"
          className={styles.footerButton}
          onClick={handleBack}
        >
          Back
        </Button>
        <div className={styles.Hdots}>
          <div className={styles.inactiveDot} />
          <div className={styles.inactiveDot} />
          <div className={styles.activeDot} />
          <div className={styles.inactiveDot} />
        </div>
        <Button
          appearance="primary"
          className={styles.footerButton}
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Tab3);
