import React, { useCallback, useMemo, useRef } from "react";
import {
  Button,
  Image,
  Input,
  makeStyles,
  shorthands,
  Tab,
  TabList,
  teamsDarkTheme,
  Text,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainHeader.css";
import Lottie from "lottie-react";
import cube from "./logo-lottie1.json";
import {
  BsAwardFill,
  BsChevronRight,
  BsPerson,
  BsSearch,
  BsTrophyFill,
} from "react-icons/bs";
import {
  MdBookmark,
  MdCollectionsBookmark,
  MdOutlineHome,
  MdSearch,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal, setShowPaywall } from "../store/items/user";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import { FaCube, FaCubes, FaRobot, FaTrophy } from "react-icons/fa";
import qs from "qs";
import { useWindowSize } from "@uidotdev/usehooks";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { isIOS } from "react-device-detect";
import AgentIcon from "./AgentIcon/AgentIcon";
import siteIcon from "./site-icon.png";

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en&gl=US";
const APP_STORE_URL =
  "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";

const useStyles = makeStyles({
  mainHeader: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    boxShadow: teamsDarkTheme.shadow2,
    display: "flex",
    justifyContent: "center",
    ...shorthands.borderBottom(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.padding("10px", "20px"),
    position: "sticky",
    top: 0,
    zIndex: 100,
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "1200px",
    ...shorthands.gap("10px"),
  },
  right: {
    ":hover": { cursor: "pointer" },
  },
  avatar: {
    width: "40px",
    height: "40px",
    ...shorthands.borderRadius("50%"),
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    ":hover": { transform: "translate(0px, -1px)" },
  },
  left: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "100vw",
  },
  inputWrap: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  Input: {
    maxWidth: "350px",
    flexBasis: "350px",
    flexGrow: 1,
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("10px"),
    ...shorthands.gap("10px"),
  },
  mobileRoot: {
    display: "flex",
    position: "sticky",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    zIndex: 100,
    width: "100%",
    ...shorthands.padding("10px", "20px"),
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
  },
  mobileAvatarWrap: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainDrawer: {
    width: "250px",
    minWidth: "40vw",
    maxHeight: "100dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    ...shorthands.padding("30px", "0px", "20px", "0px"),
    ...shorthands.gap("20px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
  },
  menuItems: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.gap("15px"),
    width: "70%",
    ...shorthands.flex(1),
  },
  brandText: {
    ...shorthands.padding("0px"),
    ...shorthands.margin("0px"),
    color: "white",
    fontWeight: "bold",
    fontSize: "24px",
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  MobileInput: {
    width: "80%",
    color: "white",
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.borderRadius("5px"),
    ...shorthands.padding("10px", "15px"),
    // fontSize: "14px",
    ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandBackground),
  },
  drawerButton: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    ...shorthands.padding("10px", "15px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorBrandBackground,
    ":active": {
      opacity: 0.5,
    },
    ":hover": {
      cursor: "pointer",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    ...shorthands.gap("10px"),
  },
  mobileText: {
    ...shorthands.padding("0px"),
    ...shorthands.margin("0px"),
    fontSize: "12px",
    color: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("5px"),
    ":active": {
      opacity: 0.5,
    },
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("5px"),
    width: "100%",
  },
});

function MainHeader() {
  const user = useSelector((state) => state.user.value);
  const revenueCat = useSelector((state) => state.user.revenueCat);
  const styles = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams =
    location?.search && qs.parse(location.search, { ignoreQueryPrefix: true });
  const navigate = useNavigate();
  const tabListValue = location.pathname.split("/")[1];
  const lottieRef = useRef(null);
  const windowSize = useWindowSize();
  const [showDrawer, setShowDrawer] = React.useState(false);

  const handleNoUser = useCallback(() => {
    dispatch(setShowModal(true));
  }, [dispatch]);

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    cursor: "pointer",
    marginRight: "10px",
    borderRadius: "5px",
  };

  const mobileLottieStyle = {
    width: `120px`,
    height: "120px",
    cursor: "pointer",
  };

  const showHeader = useMemo(() => {
    return queryParams?.header === "false" ? false : true;
  }, [queryParams]);

  const avatar = useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 40,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  const handleAvatar = useCallback(() => {
    if (!user) {
      handleNoUser();
    } else {
      navigate(`/me`);
    }
    setShowDrawer(false);
  }, [handleNoUser, navigate, user]);

  const handleAppStore = useCallback(() => {
    const location = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
    window.open(location, "_blank");
  }, []);

  const handleNavigate = useCallback(
    (path) => {
      if (path === "/ai_generation" || path === "/slicer") {
        navigate(path);
      } else if (path === "/saved" && !user) {
        handleNoUser();
      } else if (path === "/collections/me" && !user) {
        handleNoUser();
      } else {
        navigate(path);
      }
      setShowDrawer(false);
    },
    [handleNoUser, navigate, user]
  );

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
  };

  const headerText = useMemo(() => {
    if (tabListValue === "home") return "Home";
    if (tabListValue === "explore") return "Explore";
    if (tabListValue === "saved") return "Saved";
    if (tabListValue === "slicer") return "Slicer";
    if (tabListValue === "ai_generation") return "AI (beta)";
    if (tabListValue === "search") return "Search";
    if (tabListValue === "me") return "Profile";
    if (tabListValue === "models") return "3D Models";
    if (tabListValue === "contests") return "Contests";
    if (tabListValue.startsWith("model")) return "Model";
    if (tabListValue.startsWith("collection")) return "Collection";
    if (tabListValue.startsWith("checkout")) return "Checkout";
    if (tabListValue.startsWith("stripe-purchase")) return "Checkout";
    if (tabListValue.startsWith("provider_profile")) return "Profile";

    return "3D GO";
  }, [tabListValue]);

  if (
    tabListValue === "i_love_madisun" ||
    tabListValue === "stripe-purchase" ||
    tabListValue === "i_love_madisun2" ||
    tabListValue === "i_love_madisun3" ||
    tabListValue === "i_love_madisun4" ||
    tabListValue === "i_love_madisun5" ||
    !showHeader
  ) {
    return <></>;
  }

  return (
    <div className={styles.mainHeader}>
      {windowSize.width > 850 ? (
        <div className={styles.root}>
          <div className={styles.left}>
            {/* <Lottie
              animationData={cube}
              lottieRef={lottieRef}
              style={lottieStyle}
              onClick={() => handleNavigate(`/home`)}
            /> */}
            <Image
              src={siteIcon}
              style={lottieStyle}
              onClick={() => handleNavigate(`/home`)}
              alt="3d-go-icon"
              title={`3D GO App Icon`}
            />
            <TabList selectedValue={tabListValue} className="tablist">
              <Tab
                value="models"
                onClick={() => handleNavigate(`/models`)}
                icon={<FaCube size={17} />}
              >
                3D Models
              </Tab>
              <Tab
                value="contests"
                onClick={() => handleNavigate(`/contests`)}
                icon={<FaTrophy size={17} />}
              >
                Contests
              </Tab>
              <Tab
                value="collections"
                onClick={() => {
                  if (user) {
                    handleNavigate("/collections/me");
                  } else {
                    handleNavigate("/collections/search");
                  }
                }}
                icon={<MdCollectionsBookmark size={19} />}
              >
                Collections
              </Tab>
              <Tab
                value="saved"
                onClick={() => handleNavigate("/saved")}
                icon={<MdBookmark size={19} />}
              >
                Saved
              </Tab>
              <Tab
                value="slicer"
                onClick={() => handleNavigate(`/slicer`)}
                icon={<FaCubes size={19} />}
              >
                Slicer
              </Tab>
            </TabList>
          </div>
          {/* <div className={styles.inputWrap}>
            <Input
              className={styles.Input}
              placeholder="Search Millions of 3D Models..."
              value={searchText}
              onChange={handleChangeText}
              contentAfter={
                <Button
                  appearance="transparent"
                  icon={<MdSearch />}
                  size="small"
                  onClick={handleSearch}
                />
              }
            />
          </div> */}
          {/* {user && user.email} */}
          <div className={styles.right}>
            {user?.photoURL ? (
              <Image
                src={user.photoURL}
                onClick={handleAvatar}
                className={styles.avatar}
                alt="3d-go-user-icon"
                title="3D GO User Icon"
                fit="contain"
              />
            ) : (
              <Image
                src={avatar}
                onClick={handleAvatar}
                className={styles.avatar}
                alt="3d-go-user-icon"
                title="3D GO User Icon"
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.mobileRoot}>
            <div className={styles.mobileLeft}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setShowDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Text weight="semibold" size={500}>
                {headerText}
              </Text>
            </div>
            <div className={styles.mobileAvatarWrap}>
              <Image
                src={avatar}
                onClick={handleAvatar}
                className={styles.avatar}
                fit="cover"
              />
            </div>
          </div>
          <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
            <div className={styles.mainDrawer}>
              <div className={styles.brandWrap}>
                <Lottie
                  animationData={cube}
                  lottieRef={lottieRef}
                  style={mobileLottieStyle}
                  onClick={() => handleNavigate(`/home`)}
                />
                <div className={styles.agentWrap}>
                  <AgentIcon agent="thingiverse" styles={agentIconStyles} />
                  <AgentIcon agent="printables" styles={agentIconStyles} />
                  <AgentIcon agent="cults3d" styles={agentIconStyles} />
                  <AgentIcon agent="makerworld" styles={agentIconStyles} />
                  <AgentIcon agent="myminifactory" styles={agentIconStyles} />
                </div>
                <p className={styles.brandText}>3D GO</p>
              </div>
              <div className={styles.menuItems}>
                <div
                  onClick={() => handleNavigate(`/models`)}
                  className={styles.drawerButton}
                >
                  <FaCube className={styles.menuBtnIcon} size={17} />
                  3D Models
                </div>
                <div
                  onClick={() => handleNavigate(`/contests`)}
                  className={styles.drawerButton}
                >
                  <FaTrophy className={styles.menuBtnIcon} size={17} />
                  Contests
                </div>
                <div
                  onClick={() => {
                    if (user) {
                      handleNavigate("/collections/me");
                    } else {
                      handleNavigate("/collections/search");
                    }
                  }}
                  className={styles.drawerButton}
                >
                  <MdCollectionsBookmark
                    size={19}
                    className={styles.menuBtnIcon}
                  />
                  Collections
                </div>
                <div
                  onClick={() => handleNavigate("/saved")}
                  className={styles.drawerButton}
                >
                  <MdBookmark size={19} className={styles.menuBtnIcon} />
                  Saved
                </div>
                <div
                  onClick={() => handleNavigate(`/slicer`)}
                  className={styles.drawerButton}
                >
                  <FaCubes size={19} className={styles.menuBtnIcon} />
                  Slicer
                </div>
              </div>
              <div className={styles.footer}>
                <p className={styles.mobileText} onClick={handleAppStore}>
                  Use the mobile app instead <BsChevronRight />
                </p>
                <div onClick={handleAvatar} className={styles.drawerButton}>
                  <BsPerson className={styles.menuBtnIcon} size={20} />
                  Profile
                </div>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
}

export default React.memo(MainHeader);
