import React, { useCallback, useMemo, useRef } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Input,
  Checkbox,
  RadioGroup,
  Radio,
  Text,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import Lottie from "lottie-react";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import cube from "../../Components/logo-lottie1.json";
import {
  BsBookFill,
  BsChevronBarDown,
  BsChevronDown,
  BsHouse,
  BsHouseFill,
  BsTools,
  BsUsbDriveFill,
} from "react-icons/bs";
import { MdCategory, MdMusicNote, MdScience, MdToys } from "react-icons/md";
import {
  FaCube,
  FaGamepad,
  FaHamburger,
  FaPaintBrush,
  FaSort,
  FaSortAmountDown,
} from "react-icons/fa";
import {
  ChevronLeft,
  Sort,
  SortByAlpha,
  SortOutlined,
} from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";

const categories = [
  {
    icon: <MdCategory />,
    name: "All",
    value: "all",
  },
  {
    icon: <FaCube />,
    name: `3D Printing`,
    value: "3d printing",
  },
  {
    icon: <BsUsbDriveFill />,
    name: "Gadgets",
    value: "gadget",
  },
  {
    icon: <BsHouseFill />,
    name: "Household",
    value: "household",
  },
  {
    icon: <BsTools />,
    name: "Tools",
    value: "tools",
  },
  {
    icon: <FaPaintBrush />,
    name: "Art",
    value: "art",
  },
  {
    icon: <MdToys />,
    name: "Toys",
    value: "toys",
  },
  {
    icon: <FaGamepad />,
    name: "Games",
    value: "games",
  },
  {
    icon: <FaHamburger />,
    name: "Food",
    value: "food",
  },
  {
    icon: <MdMusicNote />,
    name: "Music",
    value: "music",
  },
  {
    icon: <BsBookFill />,
    name: "Education",
    value: "education",
  },
  {
    icon: <MdScience />,
    name: "Science",
    value: "science",
  },
];

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    ...shorthands.flex(1),
    maxWidth: "250px",
    boxSizing: "border-box",
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    alignItems: "flex-start",
    "@media (max-width: 850px)": {
      //   maxWidth: "100%",
      ...shorthands.flex(0),
      maxWidth: "100%",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "0px",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px", "0px"),
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.flex(1),
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("5px"),
  },
  brandText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionsWrap: {
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    backgroundColor: "red",
  },
  agentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  catH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  accordionBody: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
    ...shorthands.padding("10px"),
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: `0px 0px 2px 0px ${teamsDarkTheme.colorNeutralStroke1}`,
  },
  mobileDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.flex(1),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
  },
  mobileOptionsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.gap("5px"),
    ...shorthands.padding("10px"),
  },
  mobileAgentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

function ProfilePageFilterSort({ sort, setSort }) {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const lottieRef = React.useRef(null);

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "7px",
  };

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    // cursor: "pointer",
    marginRight: "10px",
    borderRadius: "5px",
  };

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground2 }}
    >
      {windowSize.width > 850 ? (
        <div className={styles.mainDrawer}>
          {/* heading info */}
          <div className={styles.brandWrap}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lottie
                animationData={cube}
                lottieRef={lottieRef}
                style={lottieStyle}
              />
              <p className={styles.brandText}>| 3D GO</p>
            </div>
            <div className={styles.agentWrap}>
              <AgentIcon agent="thingiverse" styles={agentIconStyles} />
              <AgentIcon agent="printables" styles={agentIconStyles} />
              <AgentIcon agent="cults3d" styles={agentIconStyles} />
              <AgentIcon agent="makerworld" styles={agentIconStyles} />
              <AgentIcon agent="myminifactory" styles={agentIconStyles} />
            </div>
          </div>
          <div
            style={{
              backgroundColor: teamsDarkTheme.colorNeutralBackground3,
            }}
          >
            <div
              className={styles.agentH}
              style={{
                gap: "10px",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSortAmountDown
                color={teamsDarkTheme.colorNeutralForeground3}
              />
              <Text
                size={400}
                weight="bold"
                style={{
                  color: teamsDarkTheme.colorNeutralForeground3,
                }}
              >
                Filter & Sort
              </Text>
            </div>
          </div>
          <div className={styles.optionsWrap}>
            <Accordion multiple defaultOpenItems={["2"]}>
              <AccordionItem value="2">
                <AccordionHeader>Sorting</AccordionHeader>
                <AccordionPanel className={styles.accordionBody}>
                  <RadioGroup
                    value={sort}
                    onChange={(_, data) => setSort(data.value)}
                  >
                    <Radio value="newest" label="Newest" />
                    <Radio value="oldest" label="Oldest" />
                    <Radio value="aZ" label="A → Z" />
                    <Radio value="zA" label="Z → A" />
                  </RadioGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className={styles.mobileDrawer}>
          <div className={styles.mobileOptionsWrap}>
            <Dropdown
              appearance="underline"
              value={sort}
              onOptionSelect={(e, data) => setSort(data.optionValue)}
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <Option value="newest" text="Newest">
                Newest
              </Option>
              <Option value="oldest" text="Newest">
                Oldest
              </Option>
              <Option value="aZ" text="Newest">
                A → Z
              </Option>
              <Option value="zA" text="Newest">
                Z → A
              </Option>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ProfilePageFilterSort);
