import React, { useEffect } from "react";
import {
  Image,
  Skeleton,
  SkeletonItem,
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
} from "@fluentui/react-components";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import createApiAgent from "../../utils/api-agent";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import "./Carousel.css";
import UserAgent from "user-agents";

const useStyles = makeStyles({
  contestCarouselWrap: {
    // width: "80%",
    display: "flex",
    // ...shorthands.flex(1),
    flexDirection: "column",

    // boxSizing: "border-box",
  },
  skeletonImage: {
    // width: "100%",
    // height: "100%",
    display: "flex",
    ...shorthands.flex(1),
  },
  contestItemWrap: {
    display: "flex",
    flexDirection: "row",
    // width: "100%",
    // height: "100%",
    ...shorthands.flex(1),
    justifyContent: "center",
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    alignItems: "center",
    cursor: "pointer",
    "@media screen and (max-width: 850px)": {
      flexDirection: "column",
    },
  },
  contestImage: {
    width: "50%",
    height: "100%",
    "@media screen and (max-width: 850px)": {
      width: "100%",
      position: "absolute",
      height: "100%",
    },
  },
  contestRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    ...shorthands.gap("10px"),
    "@media screen and (max-width: 850px)": {
      height: "min-content",
      width: "auto",
      ...shorthands.padding("10px"),
      ...shorthands.borderRadius("2px"),
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 10,
    },
  },
  slider: {
    width: "100%",
    height: "450px",
    "--swiper-pagination-color": teamsDarkTheme.colorBrandStroke1,
    "--swiper-pagination-bullet-inactive-color": "white",
    "--swiper-navigation-color": teamsDarkTheme.colorBrandStroke1,
    "--swiper-navigation-size": "30px",
    "--swiper-navigation-top-offset": "50%",
    "--swiper-navigation-sides-offset": "0px",
    ...shorthands.border("solid", "1px", "transparent"),
    ...shorthands.borderRadius("5px"),
    boxSizing: "border-box",
    ":hover": {
      ...shorthands.border("solid", "1px", teamsDarkTheme.colorBrandStroke1),
    },
    "@media screen and (max-width: 850px)": {
      height: "350px",
    },
  },
  mainText: {
    fontSize: "20px",
    fontWeight: "bold",
    // marginLeft: "40px",
  },
  contestText: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
});

const ContestItemCard = React.memo(({ competitionObj }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/provider_contest", {
      state: { competitionObj },
    });
  };

  return (
    <div className={styles.contestItemWrap} onClick={handleClick}>
      <Image
        src={competitionObj.imageUrl}
        className={styles.contestImage}
        alt="contest"
        objectFit="contain"
      />
      <div className={styles.contestRight}>
        <p className={styles.contestText}>{competitionObj.name}</p>
        <Button appearance="primary" size="large">
          View Submissions
        </Button>
      </div>
    </div>
  );
});

function ContestCarousel() {
  const styles = useStyles();
  const navigate = useNavigate();
  const apiAgent = createApiAgent("home");
  const [isLoading, setIsLoading] = React.useState(true);
  const [contestItems, setContestItems] = React.useState([]);

  const fetchContestInfo = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    let competitions = await apiAgent.getCompetitions();
    setContestItems(competitions ? competitions : []);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchContestInfo();
  }, []);

  return (
    <div
      className={styles.contestCarouselWrap}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <p className={styles.mainText}>Recent Contests</p>
      <Swiper
        navigation={true}
        autoplay={true}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        className={styles.slider}
        spaceBetween={30}
        pagination={true}
        loop={true}
        style={{ boxShadow: teamsDarkTheme.shadow2 }}
      >
        {isLoading ? (
          <SwiperSlide>
            <Skeleton className={styles.skeletonImage}>
              <SkeletonItem className={styles.skeletonImage} />
            </Skeleton>
          </SwiperSlide>
        ) : (
          <>
            {contestItems.map((item, i) => {
              const competitionObj = {
                compAgent: "printables", //hard coded agent parameter for now
                name: item.name,
                description: item.description,
                imageUrl:
                  "https://media.printables.com/" + item.teaserImageFilePath,
                isOpen: item.isOpen,
                publicUrl: `https://printables.com/contest/${item.id}-${item.slug}`,
                compId: item.id,
              };
              return (
                <SwiperSlide key={i}>
                  <ContestItemCard competitionObj={competitionObj} />
                </SwiperSlide>
              );
            })}
          </>
        )}
      </Swiper>
    </div>
  );
}

export default React.memo(ContestCarousel);
