import React, { useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MarketingPage from "./screens/MarketingPage/MarketingPage";
import MobileSlicer from "./screens/MobileSlicer/MobileSlicer";
import MainHeader from "./Components/MainHeader";
import WebSlicer from "./screens/WebSlicer/WebSlicer";
import { auth, db } from "./hooks/useFirebase";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "./Components/AuthModal/AuthModal";
import { collection, getDocs, query, where } from "firebase/firestore";
import { setLikes } from "./store/items/likes";
import { setRevenueCat, setUser, setUserLoading } from "./store/items/user";
import { setCollections, setCollectionThings } from "./store/items/things";
import Checkout from "./screens/Checkout/Checkout";
import StripeRedirect from "./Components/StripeRedirect/StripeRedirect";
import RootLoading from "./screens/RootLoading/RootLoading";
import MobileShapE from "./screens/MobileShapE/MobileShapE";
import MobileThreeDPaywall from "./Components/MobileThreeDPaywall";
import ShapEScreen from "./screens/ShapEScreen/ShapEScreen";
import ProviderProfile from "./screens/ProviderProfile/ProviderProfile";
import Onboarding from "./Components/Onboarding/Onboarding";
import NewMobilePreviewer from "./screens/MobileSlicer/NewMobilePreviewer";
import CollectionsRoute from "./routes/CollectionsRoute";
import ContestsRoute from "./routes/ContestsRoute";
import ModelsRoute from "./routes/ModelsRoute";
import CreateCollectionModal from "./Components/CollectionModals/CreateCollectionModal";
import AddToCollectionModal from "./Components/CollectionModals/AddToCollectionModal";
import ProfilePage from "./screens/ProfilePage/ProfilePage";
import NewSavedPage from "./screens/NewSavedPage/NewSavedPage";
import NewMyProfile from "./screens/MyProfile/NewMyProfile";
import SearchAd from "./Components/SearchAd";

// cloud functions api urls
// const devUrl = "http://127.0.0.1:5001/thingiverse-explore/us-central1";
const prodUrl = "https://us-central1-thingiverse-explore.cloudfunctions.net";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.value);
  const revenueCat = useSelector((state) => state?.user?.revenueCat);
  const getRevenuecat = useCallback(async (user) => {
    const resp = await fetch(prodUrl + "/revenuecat", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ mode: "getCustomer", uid: user.uid }),
    }).then(async (res) => await res.json());
    return resp.subscriber;
  }, []);

  const getCollectionThings = useCallback(async (identifier) => {
    const collections = await getDocs(
      query(
        collection(db, "CollectionThings"),
        where("userId", "==", identifier)
      )
    ).then((snapshot) => {
      return snapshot.docs.map((d) => {
        return {
          uid: d.id,
          ...d.data(),
        };
      });
    });

    return collections;
  }, []);

  const getCollections = useCallback(async (identifier) => {
    const collections = await getDocs(
      query(collection(db, "Collections"), where("userId", "==", identifier))
    ).then((snapshot) => {
      return snapshot.docs.map((d) => {
        return {
          uid: d.id,
          ...d.data(),
        };
      });
    });

    return collections;
  }, []);

  const getLikes = useCallback(async (identifier) => {
    let likes = await getDocs(
      query(collection(db, "Likes"), where("userId", "==", identifier))
    ).then((snapshot) => {
      return snapshot.docs.map((d) => {
        return {
          uid: d.id,
          ...d.data(),
        };
      });
    });

    return likes;
  }, []);

  const fetchUserData = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      // if on preview / slicer dont check for any
      const promises = [];
      promises.push(getRevenuecat(user));
      promises.push(getLikes(user.uid));
      promises.push(getCollections(user.uid));
      promises.push(getCollectionThings(user.uid));
      const [fetchedRevenuecat, likes, collections, collectionThings] =
        await Promise.all(promises);
      dispatch(setRevenueCat(fetchedRevenuecat));
      dispatch(
        setLikes(likes.sort((a, b) => b.date_saved_3dgo - a.date_saved_3dgo))
      );
      dispatch(setCollections(collections));
      dispatch(setCollectionThings(collectionThings));
    }
  }, [dispatch, getCollectionThings, getCollections, getLikes, getRevenuecat]);

  const onAuthStateChanged = useCallback(
    async (user) => {
      dispatch(setUserLoading(true));
      dispatch(setUser(user));
      if (user) {
        await fetchUserData();
      } else {
        dispatch(setRevenueCat(null));
        dispatch(setLikes([]));
      }
      dispatch(setUserLoading(false));
    },
    [dispatch, fetchUserData]
  );

  useEffect(() => {
    fetchUserData();
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, [fetchUserData, onAuthStateChanged]);

  // if (loading) {
  //   return <RootLoading />; // REMOVE & HANDLE RACE CASES
  // }

  return (
    <Router>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          position: "relative",
          width: "100vw",
        }}
      >
        <Onboarding />
        <AuthModal />
        <MainHeader />
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to="/models" replace />}
          ></Route>
          <Route exact path="/models/*" element={<ModelsRoute />}></Route>
          <Route exact path="/slicer" element={<WebSlicer />}></Route>
          <Route exact path="/saved" element={<NewSavedPage />}></Route>
          <Route
            exact
            path="/collections/*"
            element={<CollectionsRoute />}
          ></Route>
          <Route exact path="/checkout" element={<Checkout />}></Route>
          <Route exact path="/me" element={<NewMyProfile />}></Route>
          <Route exact path="/ai_generation" element={<ShapEScreen />}></Route>
          <Route exact path="/contests/*" element={<ContestsRoute />}></Route>
          <Route exact path="/profiles" element={<ProfilePage />}></Route>
          <Route exact path="/search_ad" element={<SearchAd />}></Route>
          <Route
            exact
            path="/provider_profile"
            element={<ProviderProfile />}
          ></Route>

          <Route
            exact
            path="/stripe-purchase"
            element={<StripeRedirect />}
          ></Route>
          <Route
            exact
            path="/i_love_madisun"
            element={<MobileSlicer />}
          ></Route>
          <Route
            exact
            path="/i_love_madisun2"
            element={<MobileShapE />}
          ></Route>
          <Route
            exact
            path="/i_love_madisun4"
            element={<MobileThreeDPaywall />}
          ></Route>
          <Route
            exact
            path="/i_love_madisun5"
            element={<NewMobilePreviewer />}
          ></Route>
          <Route exact path="/mmf_redirect" element={<MarketingPage />}></Route>
          <Route
            exact
            path="/sketchfab_redirect"
            element={<MarketingPage />}
          ></Route>
          <Route
            exact
            path="/thingiverse_printables_3d_printing_app"
            element={<MarketingPage />}
          ></Route>
          <Route path="*" element={<Navigate to="/models" replace />}></Route>
        </Routes>
        <AddToCollectionModal />
        <CreateCollectionModal />
      </div>
    </Router>
  );
};

export default App;
