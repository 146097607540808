import {
  Button,
  Divider,
  Input,
  Text,
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import {
  Apple,
  Check,
  CheckroomOutlined,
  Google,
  LockOutlined,
  MailOutline,
} from "@mui/icons-material";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React from "react";
import { BsCreditCardFill, BsEye, BsEyeSlash } from "react-icons/bs";
import { MdAccountCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { analytics, auth } from "../../../hooks/useFirebase";
import { setUser } from "../../../store/items/user";
import { logEvent } from "firebase/analytics";

const useStyles = makeStyles({
  oAuthText: {
    cursor: "pointer",
    color: "white",
    ...shorthands.transition("color", "0.3s"),
    ":hover": {
      color: teamsDarkTheme.colorBrandStroke1,
    },
    ":active": {
      opacity: 0.7,
    },
  },
});

const ProfileSection = ({
  email,
  setEmail,
  password,
  setPassword,
  authError,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleGoogleSignIn = () => {
    logEvent(analytics, "paywall-sign-in");
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        logEvent(analytics, "paywall-auth-sign-in");
        const user = result.user;
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleAppleSignIn = () => {
    logEvent(analytics, "paywall-sign-in");
    signInWithPopup(auth, new OAuthProvider("apple.com"))
      .then((result) => {
        logEvent(analytics, "paywall-auth-sign-in");
        const user = result.user;
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleOAuth = (type) => {
    if (type === "google") {
      handleGoogleSignIn();
    } else if (type === "apple") {
      handleAppleSignIn();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <MdAccountCircle size={16} />
        <Text weight="bold">Create Profile</Text>
      </div>
      <Divider />
      {user ? (
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            padding: "15px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            backgroundColor: teamsDarkTheme.colorStatusSuccessBackground3,
          }}
        >
          <Check />
          <Text weight="bold" style={{ color: "white" }}>
            Signed In
          </Text>
        </div>
      ) : (
        <>
          <Input
            placeholder="Email Address"
            appearance="filled-darker"
            contentBefore={<MailOutline />}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            style={{
              border: `solid ${teamsDarkTheme.colorNeutralStroke1} 1px`,
              boxShadow: "0px 3px 7px 0px rgba(0,0,0,0.75)",
            }}
          />
          <Input
            placeholder="Create Password"
            appearance="filled-darker"
            contentBefore={<LockOutlined />}
            contentAfter={
              <div
                onClick={() => setShowPassword((v) => !v)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <BsEye /> : <BsEyeSlash />}
              </div>
            }
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            type={showPassword ? "text" : "password"}
            style={{
              boxShadow: "0px 3px 7px 0px rgba(0,0,0,0.75)",
              border: `solid ${teamsDarkTheme.colorNeutralStroke1} 1px`,
            }}
          />
          {authError && (
            <Text
              style={{ color: teamsDarkTheme.colorStatusDangerForeground1 }}
            >
              * {authError}
            </Text>
          )}
          <div
            style={{
              display: "flex",
              gap: "5px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                display: "flex",
                flex: 1,
              }}
              onClick={(e) => handleOAuth("google")}
              icon={<Google style={{ width: "16px" }} />}
            >
              Google
            </Button>
            <Button
              icon={<Apple style={{ width: "16px" }} />}
              onClick={(e) => handleOAuth("apple")}
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              Apple
            </Button>
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <BsCreditCardFill size={16} />
        <Text weight="bold">Payment Information</Text>
      </div>
      <Divider />
    </div>
  );
};

export default React.memo(ProfileSection);
