import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Input,
  Checkbox,
  RadioGroup,
  Radio,
  Text,
  Dropdown,
  Option,
  Image,
  Dialog,
  DialogActions,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogBody,
  DialogSurface,
  Toaster,
  useId,
  useToastController,
  Toast,
  ToastTitle,
  Spinner,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import Lottie from "lottie-react";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import cube from "../../Components/logo-lottie1.json";
import {
  BsBookFill,
  BsChevronBarDown,
  BsChevronDown,
  BsHouse,
  BsHouseFill,
  BsPencilFill,
  BsPersonCircle,
  BsTools,
  BsUsbDriveFill,
} from "react-icons/bs";
import {
  MdCategory,
  MdEditAttributes,
  MdLogout,
  MdMusicNote,
  MdPersonPinCircle,
  MdScience,
  MdToys,
} from "react-icons/md";
import {
  FaCube,
  FaEdit,
  FaGamepad,
  FaHamburger,
  FaPaintBrush,
  FaSort,
  FaSortAmountDown,
} from "react-icons/fa";
import {
  ChevronLeft,
  Create,
  Edit,
  Logout,
  Sort,
  SortByAlpha,
  SortOutlined,
} from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signOut, updateProfile } from "firebase/auth";
import { auth } from "../../hooks/useFirebase";
import {
  RegExpMatcher,
  englishDataset,
  englishRecommendedTransformers,
} from "obscenity";
import { setUser } from "../../store/items/user";
import StripeButton from "./StripeButton";
import imageCompression from "browser-image-compression";

// const postImageUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/updateProfileImage";

const postImageUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/updateProfileImage";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    ...shorthands.flex(1),
    maxWidth: "250px",
    boxSizing: "border-box",
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    alignItems: "flex-start",
    "@media (max-width: 850px)": {
      //   maxWidth: "100%",
      ...shorthands.flex(0),
      maxWidth: "100%",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "0px",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px", "0px"),
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.flex(1),
  },
  headerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.gap("10px"),
  },
  brandText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionsWrap: {
    display: "flex",
    flexDirection: "column",
  },
  mobileDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.flex(1),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
  },
  mobileOptionsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.gap("5px"),
    ...shorthands.padding("10px"),
  },
  usernameText: {
    cursor: "pointer",
    userSelect: "none",
    ":active": {
      opacity: 0.6,
    },
  },
  actionBtn: {
    ...shorthands.margin("0px", "10px"),
  },
  avatarWrap: {
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    ":active": { opacity: 0.7 },
  },
});

function NewMyProfileTop() {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const windowSize = useWindowSize();
  const lottieRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const [showChangeUsername, setShowChangeUsername] = useState(false);
  const [newUsername, setNewUsername] = useState("");

  const handleLogout = useCallback(() => {
    signOut(auth);
    navigate(`/models`);
  }, [navigate]);

  const handleUpdateUsername = useCallback(async () => {
    // check for profanity
    const matcher = new RegExpMatcher({
      ...englishDataset.build(),
      ...englishRecommendedTransformers,
    });

    if (matcher.hasMatch(newUsername)) {
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>No bad words please</ToastTitle>
        </Toast>,
        { position: "bottom", intent: "warning" }
      );
      setNewUsername("");
      return;
    }

    let user = getAuth().currentUser;
    await updateProfile(user, { displayName: newUsername });
    let updatedUser = getAuth().currentUser;
    dispatch(setUser(updatedUser));
    setNewUsername("");
  }, [dispatch, dispatchToast, newUsername]);

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    marginRight: "10px",
    borderRadius: "5px",
  };
  const avatar = useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 125,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  const handleAvatar = useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      try {
        setLoading(true);
        // Compress the image
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 400,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        // Convert compressed file to base64
        const base64 = await imageCompression.getDataUrlFromFile(
          compressedFile
        );

        // Post the base64 image to the server
        const response = await fetch(postImageUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            base64: base64.split(",")[1], // Remove the data URL prefix
            uid: user.uid, // Replace with actual UID
            format: "jpeg",
          }),
        });

        const responseData = await response.json();
        if (responseData.error) {
          console.log("responseData", responseData.error);
          dispatchToast(
            <Toast>
              <ToastTitle>Failed to update profile image</ToastTitle>
            </Toast>,
            { position: "bottom", intent: "warning" }
          );
        } else if (responseData.success) {
          await auth.currentUser.reload();
          dispatch(setUser(auth.currentUser));
          dispatchToast(
            <Toast>
              <ToastTitle>Profile image updated</ToastTitle>
            </Toast>,
            { position: "bottom", intent: "success" }
          );
        }
      } catch (error) {
        console.error("Error uploading the image:", error);
        alert("An error occurred while uploading the image. Please try again.");
      } finally {
        input.value = "";
        setLoading(false);
      }
    };

    input.click();
  }, [dispatch, dispatchToast, user]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground2 }}
    >
      {windowSize.width > 850 ? (
        <div className={styles.mainDrawer}>
          {/* heading info */}
          <div className={styles.brandWrap}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lottie
                animationData={cube}
                lottieRef={lottieRef}
                style={lottieStyle}
              />
              <p className={styles.brandText}>| 3D GO</p>
            </div>
            <div
              style={{
                backgroundColor: teamsDarkTheme.colorNeutralBackground3,
                width: "100%",
              }}
            >
              <div
                style={{
                  gap: "5px",
                  padding: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <BsPersonCircle
                  color={teamsDarkTheme.colorNeutralForeground3}
                />
                <Text
                  size={400}
                  weight="bold"
                  style={{
                    color: teamsDarkTheme.colorNeutralForeground3,
                  }}
                >
                  My Profile
                </Text>
              </div>
            </div>
            <div className={styles.headerContent}>
              <div className={styles.avatarWrap}>
                {user?.photoURL ? (
                  <Image
                    src={user.photoURL}
                    onClick={handleAvatar}
                    fit="contain"
                    style={{
                      width: 125,
                      height: 125,
                      borderRadius: 100,
                    }}
                    alt="3d-go-user-icon"
                    title="3D GO User Icon"
                  />
                ) : (
                  <Image
                    src={avatar}
                    onClick={handleAvatar}
                    style={{
                      width: 125,
                      height: 125,
                      borderRadius: 100,
                    }}
                    alt="3d-go-user-icon"
                    title="3D GO User Icon"
                  />
                )}
                <FaEdit style={{ position: "absolute", bottom: 0, right: 0 }} />
              </div>
              <Text
                className={styles.usernameText}
                onClick={() => setShowChangeUsername(true)}
              >
                {user.displayName ?? "Set Display Name"}{" "}
                <BsPencilFill size={12} />
              </Text>
            </div>
          </div>
          <StripeButton />
          <Button
            className={styles.actionBtn}
            icon={<MdLogout size={15} />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      ) : (
        <div className={styles.mobileDrawer}>
          <div className={styles.mobileOptionsWrap}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "5px",
              }}
            >
              <BsPersonCircle color={teamsDarkTheme.colorNeutralForeground3} />
              <Text
                size={400}
                weight="bold"
                style={{
                  color: teamsDarkTheme.colorNeutralForeground3,
                }}
              >
                My Profile
              </Text>
            </div>
            {/* AVATAR / edit name */}
            <div
              className={styles.headerContent}
              style={{
                width: "100%",
              }}
            >
              <div className={styles.avatarWrap}>
                {user?.photoURL ? (
                  <Image
                    src={user.photoURL}
                    onClick={handleAvatar}
                    fit="contain"
                    style={{
                      width: 125,
                      height: 125,
                      borderRadius: 100,
                      // backgroundColor: teamsDarkTheme.colorNeutralBackground1,
                    }}
                    alt="3d-go-user-icon"
                    title="3D GO User Icon"
                  />
                ) : (
                  <Image
                    src={avatar}
                    onClick={handleAvatar}
                    style={{
                      width: 125,
                      height: 125,
                      borderRadius: 100,
                    }}
                    alt="3d-go-user-icon"
                    title="3D GO User Icon"
                  />
                )}
                <FaEdit style={{ position: "absolute", bottom: 0, right: 0 }} />
              </div>
              <Text
                className={styles.usernameText}
                onClick={() => setShowChangeUsername(true)}
              >
                {user.displayName ?? "Set Display Name"}{" "}
                <BsPencilFill size={12} />
              </Text>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={showChangeUsername}
        onOpenChange={(event, data) => setShowChangeUsername(data.open)}
      >
        <DialogSurface
          style={{
            backgroundColor: teamsDarkTheme.colorNeutralBackground3,
          }}
        >
          <DialogBody>
            <DialogTitle
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                borderRadius: "5px",
                padding: "10px 10px",
                boxShadow: teamsDarkTheme.shadow2,
                backgroundColor: teamsDarkTheme.colorNeutralBackground1,
                flexShrink: 1,
              }}
            >
              <Text
                style={{
                  fontSize: "20px",
                }}
                weight="bold"
              >
                Set Display Name
              </Text>
            </DialogTitle>
            <DialogContent>
              <Input
                appearance="underline"
                placeholder="Type Here..."
                style={{ flex: 1, width: "100%" }}
                onChange={(e, data) => setNewUsername(data.value)}
                value={newUsername}
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  icon={<BsPencilFill size={15} />}
                  onClick={handleUpdateUsername}
                  style={{
                    display: "flex",
                    width: "max-content",
                  }}
                >
                  Update
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Toaster toasterId={toasterId} />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default React.memo(NewMyProfileTop);
