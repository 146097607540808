import React, { useCallback, useMemo, useRef } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Input,
  Button,
  Text,
  Image,
  Divider,
  Title3,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import ContestCarousel from "../../Components/ContestCarousel/ContestCarousel";
import createApiAgent from "../../utils/api-agent";
import { Pagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { ChevronRight, Close } from "@mui/icons-material";
import { createAvatar } from "@dicebear/core";
import { thumbs } from "@dicebear/collection";
import cube from "../../Components/logo-lottie1.json";
import Lottie from "lottie-react";
import CollectionCard from "../../Components/CollectionCard";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../hooks/useFirebase";

async function getUserCollections(identifier, params) {
  const orderByField =
    params.sort === "newest" || params.sort === "oldest"
      ? "created_at"
      : "title";
  const direction =
    params.sort === "oldest" || params.sort === "aZ" ? "asc" : "desc";

  // Construct the query with multiple conditions
  const collectionsQuery = query(
    collection(db, "Collections"),
    where("userId", "==", identifier),
    where("isPrivate", "==", false),
    orderBy(orderByField, direction)
  );

  // Execute the query
  const querySnapshot = await getDocs(collectionsQuery);

  // Map over the documents to extract the data
  const collections = querySnapshot.docs.map((d) => ({
    uid: d.id,
    ...d.data(),
  }));

  return collections;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
  profileTitle: {
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
});

function ProfilePageComponent({ sort, normalProfile }) {
  const styles = useStyles();
  const location = useLocation();
  const [profileCollections, setProfileCollections] = React.useState(
    new Array(6).fill(0)
  );
  const profileId = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true })?.id,
    [location.search]
  );

  const [loading, setLoading] = React.useState(true);

  const loadCollection = useCallback(async () => {
    setLoading(true);
    const params = {
      sort: sort,
    };
    const results = await getUserCollections(profileId, params);
    Array.isArray(results) && setProfileCollections(results);
    setLoading(false);
  }, [profileId, sort]);

  React.useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  const avatar = useMemo(() => {
    return createAvatar(thumbs, {
      seed: "Aneka",
      scale: 75,
      size: 25,
      backgroundColor: [teamsDarkTheme.colorNeutralBackground2.slice(1)],
      shapeColor: [teamsDarkTheme.colorBrandBackground.slice(1)],
      eyes: ["variant4W16"],
    }).toDataUriSync();
  }, []);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {normalProfile?.photoURL ? (
          <Image
            src={normalProfile?.photoURL}
            fit="contain"
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "100%",
              border: "solid white 2px",
            }}
          />
        ) : (
          <Image
            src={avatar}
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "100%",
              border: "solid white 2px",
            }}
          />
        )}
        <div className={styles.profileTitle}>
          <Lottie
            animationData={cube}
            style={{
              width: `30px`,
              height: "30px",
            }}
          />
          <Title3>{normalProfile?.displayName ?? "Awesome 3D GO User"}</Title3>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Text
          weight="bold"
          style={{
            fontSize: 20,
          }}
        >
          Public Collections
        </Text>
      </div>
      <Divider style={{ flex: 0 }} />
      <div className={styles.resultsWrap}>
        {profileCollections.length ? (
          <>
            {profileCollections.map((c, i) => {
              return (
                <div key={i}>
                  <CollectionCard collection={c} />
                </div>
              );
            })}
          </>
        ) : (
          "No Results Found..."
        )}
      </div>
    </div>
  );
}

export default React.memo(ProfilePageComponent);
