import qs from "qs";

const queryString = (params) => qs.stringify(params);

const getThingiverse = async (params) => {
  // dev url
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/thingiverse?" +
  //   queryString(params);
  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/thingiverse?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getCults3D = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/cults3d?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/cults3d?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getMyMiniFactory = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/myminifactory?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/myminifactory?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getPrintables = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/printables?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/printables?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getHome = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/home?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/home?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getMakerworld = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/makerworld?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/makerworld?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const getBlend = async (params) => {
  // const url =
  //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/blend?" +
  //   queryString(params);

  const url =
    "https://us-central1-thingiverse-explore.cloudfunctions.net/blend?" +
    queryString(params);

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await resp.json();
  } catch (e) {
    return { error: e };
  }
};

const createApiAgent = (agent) => {
  const getCalls = {
    thingiverse: getThingiverse,
    cults3d: getCults3D,
    myminifactory: getMyMiniFactory,
    printables: getPrintables,
    home: getHome,
    blend: getBlend,
    makerworld: getMakerworld,
  };

  const megaAgent = {
    featured: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "featured",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    search: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "search",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    art: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "art",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    gadgets: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "gadgets",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    hobby: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "hobby",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    household: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "household",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    tools: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "tools",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    toys___games: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "toys___games",
        per_page: 24,
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getExtraDetails: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "getExtraDetails",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getPreviewFiles: async (params) => {
      const queryParams = {
        ...params, // page
        mode: "getPreviewFiles",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    creatorThings: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "creatorThings",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getCompetitions: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "competitions",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    competitionDetails: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "competitionDetails",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    popularModels: async (params) => {
      const queryParams = {
        ...params, // page & identifier
        mode: "popularModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    singleModel: async (params) => {
      const queryParams = {
        ...params,
        mode: "singleModel",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    modelComments: async (params) => {
      const queryParams = {
        ...params,
        mode: "modelComments",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    relatedModels: async (params) => {
      const queryParams = {
        ...params,
        mode: "relatedModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getModelsByCategory: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "getModelsByCategory",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    searchModels: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "searchModels",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    getContests: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "getContests",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
    contestDetails: async (params) => {
      const queryParams = {
        ...params,
        // category, limit, sorting, providers, page
        mode: "contestDetails",
      };
      const resp = await getCalls[agent](queryParams);
      return resp;
    },
  };

  const agents = [
    "thingiverse",
    "cults3d",
    "myminifactory",
    "printables",
    "home",
    "blend",
    "makerworld",
  ];
  if (!agents.includes(agent)) {
    return null;
  }
  return megaAgent;
};

export default createApiAgent;
