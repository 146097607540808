import React from "react";
import { Route, Routes } from "react-router-dom";
import CollectionsPage from "../screens/CollectionsPage/CollectionsPage";
import ContestsPage from "../screens/ContestsPage/ContestsPage";
import ProviderContest from "../screens/ProviderContest/ProviderContest";

const ContestsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ContestsPage />} />
      <Route path="contest" element={<ProviderContest />} />
    </Routes>
  );
};

export default React.memo(ContestsRoute);
