import {
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { analytics, auth } from "../../../hooks/useFirebase";
import { setShowModal, setUser } from "../../../store/items/user";
import {
  Button,
  Input,
  Text,
  Title3,
  Toast,
  ToastTitle,
  makeStyles,
  teamsDarkTheme,
  useId,
  useToastController,
  shorthands,
  Toaster,
} from "@fluentui/react-components";
import Lottie from "lottie-react";
import cube from "../../logo-lottie1.json";
import { MdLock, MdMail } from "react-icons/md";
import AgentIcon from "../../AgentIcon/AgentIcon";
import { logEvent } from "firebase/analytics";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    // justifyContent: "center",
    boxSizing: "border-box",
    ...shorthands.gap("10px"),
    ...shorthands.flex(1),
    height: "100%",
    "> *": {
      // backgroundColor: teamsDarkTheme.colorNeutralBackground1,
      fontWeight: "bold",
      boxShadow: teamsDarkTheme.shadow2,
    },
  },
  Input: {
    minWidth: "300px",
  },
  Button: {
    minWidth: "300px",
    backgroundColor: teamsDarkTheme.colorBrandBackground,
  },
  ContinueButton: {
    minWidth: "275px",
  },
  toggleText: {
    cursor: "pointer",
    transitionDuration: "150ms",
    ":hover": {
      opacity: 0.5,
    },
  },
  agentIconWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.gap("10px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.padding("10px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: teamsDarkTheme.shadow2,
  },
  bgp: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.padding("5px", "10px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: teamsDarkTheme.shadow2,
  },
  bgp2: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.padding("10px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: teamsDarkTheme.shadow2,
  },
  inputWrap: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
  },
});

const LoginTab = ({ swiperRef }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmedPassword, setConfirmedPassword] = React.useState("");
  const [isSignUp, setIsSignUp] = React.useState(true);

  const agentStyles = {
    width: "35px",
    height: "35px",
    borderRadius: "5px",
  };

  const handleClose = () => {
    setIsSignUp(true);
    setConfirmedPassword("");
    setPassword("");
    setEmail("");
  };

  // const handleContinue = useCallback(() => {}, []);

  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);
  const appleProvider = useMemo(() => new OAuthProvider("apple.com"), []);
  appleProvider.addScope("email");
  appleProvider.addScope("name");

  const handleGoogleSignIn = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [dispatch, googleProvider]);

  const handleAppleSignIn = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [appleProvider, dispatch]);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notifyError = useCallback(
    (msg) => {
      dispatchToast(
        <Toast>
          <ToastTitle>{msg}</ToastTitle>
        </Toast>,
        { intent: "error" }
      );
    },
    [dispatchToast]
  );

  const basicChecks = useCallback((email, password) => {}, []); //TODO
  const handleManualAuth = useCallback(() => {
    logEvent(analytics, "user-sign-in");
    const failedCheck = basicChecks(email, password);
    const confirmedPasswordCheck = password === confirmedPassword;
    if (failedCheck) {
      notifyError(failedCheck);
      return;
    } else if (isSignUp && !confirmedPasswordCheck) {
      notifyError("Passwords do not match");
      return;
    }
    const authFunc = isSignUp
      ? createUserWithEmailAndPassword
      : signInWithEmailAndPassword;
    authFunc(auth, email, password)
      .then((result) => {
        const user = result.user;
        dispatch(setUser(user));
        setEmail("");
        setPassword("");
        setConfirmedPassword("");
      })
      .catch((err) => {
        notifyError(err.message);
      });
  }, [
    basicChecks,
    confirmedPassword,
    dispatch,
    email,
    isSignUp,
    notifyError,
    password,
  ]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <div className={styles.main}>
      <Lottie
        animationData={cube}
        style={{
          width: `90px`,
          height: "90px",
          boxShadow: "none",
        }}
      />
      {/* <Title3>Please Log In or Sign Up</Title3> */}
      <div className={styles.agentIconWrap}>
        <AgentIcon agent="thingiverse" styles={agentStyles} />
        <AgentIcon agent="printables" styles={agentStyles} />
        <AgentIcon agent="cults3d" styles={agentStyles} />
        <AgentIcon agent="makerworld" styles={agentStyles} />
        <AgentIcon agent="myminifactory" styles={agentStyles} />
      </div>
      <div className={styles.bgp}>
        <Text size={600} weight="bold">
          Search Millions | 3D GO
        </Text>
      </div>
      <div className={styles.inputWrap}>
        <div className={styles.bgp2}>
          <Input
            className={styles.Input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="email"
            contentBefore={<MdMail />}
          />
        </div>
        <div className={styles.bgp2}>
          <Input
            className={styles.Input}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            contentBefore={<MdLock />}
          />
        </div>
        {isSignUp && (
          <div className={styles.bgp2}>
            <Input
              className={styles.Input}
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              placeholder="Confirm Password"
              type="password"
            />
          </div>
        )}
      </div>
      <Button className={styles.Button} onClick={handleManualAuth}>
        {isSignUp ? "Sign Up" : "Sign In"}
      </Button>
      <div className={styles.bgp}>
        <Text
          onClick={() => setIsSignUp((v) => !v)}
          className={styles.toggleText}
          weight="bold"
        >
          {isSignUp ? "Already have an account?" : "Create New Account"}
        </Text>
      </div>
      <Button
        onClick={handleGoogleSignIn}
        className={styles.ContinueButton}
        icon={<AgentIcon agent={"google"} />}
      >
        Continue with Google
      </Button>
      <Button
        onClick={handleAppleSignIn}
        className={styles.ContinueButton}
        icon={<AgentIcon agent={"apple"} />}
      >
        Continue with Apple
      </Button>
      {/* <Toaster toasterId={toasterId} /> */}
    </div>
  );
};

export default React.memo(LoginTab);
