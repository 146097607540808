import { makeStyles } from "@fluentui/react-components";
import React from "react";
import { BarLoader } from "react-spinners";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
});

function RootLoading() {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <BarLoader color="white" />
    </div>
  );
}

export default React.memo(RootLoading);
