import React, { useCallback, useMemo } from "react";
import {
  Button,
  Image,
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import createApiAgent from "../../utils/api-agent";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { modelNormalizer } from "../../utils/normalizers";
import ThingCard from "../../Components/ThingCard/ThingCard";
import { PulseLoader } from "react-spinners";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  providerContestWrap: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    ...shorthands.padding("20px", "0px"),
  },
  crunchyyy: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "1200px",
  },
  contestItemWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "450px",
    justifyContent: "center",
    ...shorthands.gap("10px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    alignItems: "center",
    "@media screen and (max-width: 850px)": {
      flexDirection: "column",
      height: "auto",
    },
  },
  imageWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
    "@media screen and (max-width: 850px)": {
      width: "100%",
      height: "50%",
    },
  },
  imgOverlay: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(0,0,0,0.5)",
    ...shorthands.padding("10px", "20px"),
    ...shorthands.borderRadius("5px"),
  },
  contestImage: {
    width: "100%",
    height: "100%",
  },
  contestRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    // flex: 1,
    flexGrow: 1,
    width: "50%",
    height: "100%",
    ...shorthands.gap("10px"),
    "@media screen and (max-width: 850px)": {
      width: "100%",
      height: "auto",
      justifyContent: "flex-start",
    },
  },
  contestText: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  descriptionWrap: {
    ...shorthands.padding("5px"),
    height: "auto",
    "& *": {
      color: teamsDarkTheme.colorNeutralForeground1,
      maxWidth: "100%",
    },
  },
  openURL: {
    ...shorthands.padding("10px"),
  },
});

function ProviderContest() {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const competitionObj = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );
  const tabBodyDiv = React.useRef(null);

  const apiAgent = useMemo(() => createApiAgent("blend"), []);
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [things, setThings] = React.useState(new Array(24).fill(0));

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const handleClick = React.useCallback(
    (thing) => {
      const params = qs.stringify({
        agent: thing.goFinal_agent,
        id: thing.goFinal_shareId,
      });
      navigate(`/models/model?${params}`, { state: { thing } });
    },
    [navigate]
  );

  const methods = React.useMemo(() => {
    return {
      setSelectedThing: handleClick,
    };
  }, [handleClick]);

  const fetchContestDetails = useCallback(async () => {
    if (!loading) setLoading(true);
    let compThings = await apiAgent.contestDetails({
      page: 1,
      limit: 24,
      compId: competitionObj?.compId,
      agent: competitionObj?.agent,
    });

    setThings(compThings);
    setLoading(false);
  }, [apiAgent, competitionObj?.agent, competitionObj?.compId, loading]);

  const handleURL = () => {
    window.open(competitionObj.publicUrl, "_blank");
  };

  //TODO
  const handleLoadMore = useCallback(async () => {
    if (
      !things ||
      !things.length ||
      things.length % 24 !== 0 ||
      loadingMore ||
      loading
    )
      return;
    setLoadingMore(true);
    const newPage = Number(page) + 1;
    setPage(newPage);
    let moreThings;
    moreThings = await apiAgent.contestDetails({
      page: newPage,
      limit: 24,
      compId: competitionObj?.compId,
      agent: competitionObj?.agent,
    });
    if (moreThings && moreThings.length) {
      setThings((v) => [...v, ...moreThings]);
    }
    setLoadingMore(false);
  }, [
    apiAgent,
    competitionObj?.agent,
    competitionObj?.compId,
    loading,
    loadingMore,
    page,
    things,
  ]);

  const handleScroll = useCallback(
    async (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isNearEnd = scrollHeight - scrollTop <= clientHeight * 1.5;
      if (isNearEnd && tabBodyDiv.current) {
        handleLoadMore();
        //TODO bug if all the way scrolled down
      }
    },
    [handleLoadMore]
  );

  React.useEffect(() => {
    fetchContestDetails();
  }, []);

  if (!competitionObj) {
    navigate("/");
    return <></>;
  } else {
    return (
      <>
        <Helmet>
          <title>3D Model Contest</title>
          <meta
            name="description"
            content={`${competitionObj?.name ?? "3D Model Contest"} - ${
              competitionObj?.agent ?? "Check It Out"
            }`}
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div
          className={styles.providerContestWrap}
          onScroll={handleScroll}
          ref={tabBodyDiv}
          style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        >
          <div className={styles.crunchyyy}>
            <div className={styles.contestItemWrap}>
              <div className={styles.imageWrap}>
                <Image
                  className={styles.contestImage}
                  src={competitionObj.imageUrl}
                  fit="contain"
                  loading="lazy"
                />
                <div className={styles.imgOverlay}>
                  <p className={styles.contestText}>{competitionObj.name}</p>
                </div>
              </div>
              <div className={styles.contestRight}>
                <div className={styles.descriptionWrap}>
                  <p className={styles.contestText}>Description</p>
                  <div className={styles.descriptionWrap}>
                    {parse(competitionObj.description)}
                  </div>
                </div>
                <div className={styles.openURL}>
                  <Button size="large" onClick={handleURL}>
                    Open URL
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                {things &&
                  things.length &&
                  things.map((thing, i) => {
                    const normalThing =
                      thing !== 0 &&
                      modelNormalizer(thing, competitionObj.agent);

                    return (
                      <ThingCard
                        thing={normalThing}
                        agent={competitionObj.agent}
                        loading={loading}
                        methods={methods}
                        key={i}
                      />
                    );
                  })}
              </div>
              {loadingMore && <PulseLoader color="white" />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

// .ResultsWrap {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   gap: 5px;
//   justify-content: space-between;
//   align-items: flex-start;
// }

// .SuperWrap {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding-top: 1rem;
//   padding-bottom: 2rem;
// }

export default React.memo(ProviderContest);
