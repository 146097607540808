import React, { useCallback, useMemo, useRef } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Input,
  Button,
  Text,
} from "@fluentui/react-components";
import { useLocation, useNavigate } from "react-router-dom";
import { modelNormalizer } from "../../utils/normalizers";
import qs from "qs";
import ThingCard from "../../Components/ThingCard/ThingCard";
import ContestCarousel from "../../Components/ContestCarousel/ContestCarousel";
import createApiAgent from "../../utils/api-agent";
import { Pagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { BsInfoCircle } from "react-icons/bs";
import { ChevronRight, Close } from "@mui/icons-material";
import CollectionCard from "../../Components/CollectionCard";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../hooks/useFirebase";

const searchCollectionsByCategory = async (category, params) => {
  const finalStr = category
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

  let q;

  const orderByField =
    params.sort === "newest" || params.sort === "oldest"
      ? "created_at"
      : "title";
  const direction =
    params.sort === "oldest" || params.sort === "aZ" ? "asc" : "desc";

  if (params.cursor && params.cursor !== "end") {
    // Assuming params.cursor is a document snapshot for startAfter
    const cursorSnapshot = await getDoc(
      doc(db, "CollectionTagJoinTable", params.cursor)
    );
    q = query(
      collection(db, "CollectionTagJoinTable"),
      where("tag_id", "==", finalStr),
      orderBy(orderByField, direction),
      startAfter(cursorSnapshot),
      limit(params.limit)
    );
  } else {
    q = query(
      collection(db, "CollectionTagJoinTable"),
      where("tag_id", "==", finalStr),
      orderBy(orderByField, direction),
      limit(params.limit)
    );
  }

  const joinTableSnapshot = await getDocs(q);
  let nextCursor = null;
  if (
    !joinTableSnapshot.empty &&
    joinTableSnapshot.docs.length === params.limit
  ) {
    nextCursor = joinTableSnapshot.docs[joinTableSnapshot.docs.length - 1].id;
  } else {
    nextCursor = "end";
  }

  const collectionArr = [];
  for (const item of joinTableSnapshot.docs) {
    const collectionDocRef = doc(db, "Collections", item.data().collectionId);
    const collectionDocSnap = await getDoc(collectionDocRef);
    if (collectionDocSnap.exists() && !collectionDocSnap.data().isPrivate) {
      collectionArr.push({
        uid: collectionDocSnap.id,
        ...collectionDocSnap.data(),
      });
    }
  }

  return { collections: collectionArr, cursor: nextCursor };
};

const searchCollectionsByText = async (text, params) => {
  const API_KEY = "455e84c2a08fc30d8306dcc58421f8c1";
  const APPLICATION_ID = "6FN0S4K4D0";
  const parameters = {
    query: text,
    hitsPerPage: params.limit,
    page: params.page - 1,
    filters: "isPrivate:false",
  };
  const queryString = qs.stringify(parameters);
  let index = "Collections";
  if (params.sort === "newest") {
    index = "Collections_created_at_newest";
  } else if (params.sort === "oldest") {
    index = "Collections_created_at_oldest";
  } else if (params.sort === "aZ") {
    index = "Collections_title_az";
  } else if (params.sort === "zA") {
    index = "Collections_title_za";
  }

  try {
    const resp = await fetch(
      `https://${APPLICATION_ID}-dsn.algolia.net/1/indexes/${index}?${queryString}`,
      {
        method: "GET",
        headers: {
          "X-Algolia-API-Key": API_KEY,
          "X-Algolia-Application-Id": APPLICATION_ID,
        },
      }
    );
    let parsedCollections = await resp.json();
    if (parsedCollections.hits.length > 0) {
      parsedCollections.hits = parsedCollections.hits.map((hit) => {
        return { ...hit, uid: hit.objectID };
      });
    }
    return parsedCollections.hits;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  resultsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    ...shorthands.gap("5px"),
    ...shorthands.flex(1),
  },
});

function SearchCollectionsComponent({
  page,
  setPage,
  sort,
  searchText,
  setSearchText,
  setDidSearch,
  didSearch,
  cursor,
  setCursor,
}) {
  const styles = useStyles();
  const inputRef = useRef();
  const navigate = useNavigate();
  const [things, setThings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const loadModels = useCallback(async () => {
    setLoading(true);
    const params = {
      sort: sort,
      page: page,
      cursor: cursor,
      limit: 24,
    };
    let func;
    let results;

    if (searchText && searchText.length) {
      const isTag = searchText.toLowerCase().startsWith("tag:");
      const searchString = isTag ? searchText.slice(4) : searchText;
      func = isTag ? searchCollectionsByCategory : searchCollectionsByText;
      const resp = await func(searchString, params);
      if (resp.cursor) {
        setCursor(resp.cursor);
      }
      results = resp.collections ?? resp;
    } else {
      results = [];
    }
    Array.isArray(results) && setThings(results);
    setLoading(false);
  }, [sort, page, cursor, searchText, setCursor]);

  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  React.useEffect(() => {
    loadModels();
  }, [sort, page]);

  const handlePageChange = React.useCallback(
    (e, value) => {
      const modelsPageRef = document.getElementById("modelsPageRef");
      modelsPageRef.scrollTo({
        top: 0,
        behavior: "smooth", // Optional, smooth scrolling animation
      });
      setPage(value);
    },
    [setPage]
  );

  const handleSearchPress = React.useCallback(() => {
    if (!searchText) return;
    if (!didSearch) setDidSearch(true);
    setPage(1);
    setCursor(null);
    loadModels();
  }, [didSearch, loadModels, searchText, setCursor, setDidSearch, setPage]);

  const handleClosePress = React.useCallback(() => {
    setThings([]);
    setSearchText("");
    setPage(1);
    setCursor(null);
    setDidSearch(false);
    loadModels();
    navigate("/collections/me");
  }, [loadModels, navigate, setCursor, setDidSearch, setPage, setSearchText]);

  const handleKeyPress = React.useCallback(
    (e) => {
      if (e.key === "Enter") {
        // Prevent the default action to avoid form submission or any other unwanted behavior
        e.preventDefault();
        // Initiate search
        handleSearchPress();
      }
    },
    [handleSearchPress]
  );

  const extraPages = useMemo(() => {
    return things.length % 24 === 0 && things.length > 0 ? 1 : 0;
  }, [things]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Input
          ref={inputRef}
          style={{
            color: "white",
            fontSize: "24px",
            width: "100%",
            backgroundColor: teamsDarkTheme.colorNeutralBackground2,
            ...shorthands.padding("10px", "20px"),
            ...shorthands.border(
              "1px",
              "solid",
              teamsDarkTheme.colorNeutralStroke1
            ),
            boxSizing: "border-box",
            ...shorthands.borderRadius("5px"),
          }}
          value={searchText}
          onKeyDown={handleKeyPress}
          contentBefore={
            <FaSearch
              size={24}
              style={{ marginRight: "5px", padding: "5px 0px" }}
            />
          }
          contentAfter={
            searchText.length ? (
              <div style={{ gap: "5px", display: "flex" }}>
                <Button
                  appearance="transparent"
                  size="medium"
                  icon={<Close />}
                  onClick={handleClosePress}
                />
                <Button
                  appearance="primary"
                  size="medium"
                  icon={<ChevronRight />}
                  iconPosition="after"
                  shape="circular"
                  onClick={handleSearchPress}
                >
                  Search
                </Button>
              </div>
            ) : null
          }
          size="large"
          onChange={(e, data) => setSearchText(data.value)}
          appearance="underline"
          placeholder="Search Collections..."
        />
        <Text
          style={{
            opacity: 0.5,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
          size={200}
        >
          <BsInfoCircle />
          Start your search with 'tag:' to search any category
        </Text>
      </div>
      <div className={styles.resultsWrap}>
        {things && things.length ? (
          <>
            {things.map((c, i) => {
              return (
                <div key={i}>
                  <CollectionCard collection={c} />
                </div>
              );
            })}
          </>
        ) : didSearch ? (
          "No Results Found..."
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexGrow: 1,
            }}
          >
            <Text>Try some examples: gadgets, pikachu, tag:art</Text>
          </div>
        )}
      </div>
      <Pagination
        variant="outlined"
        shape="rounded"
        page={page}
        count={page + extraPages}
        onChange={handlePageChange}
        sx={{
          ".MuiPaginationItem-root": {
            color: teamsDarkTheme.colorNeutralForeground1,
            borderColor: teamsDarkTheme.colorNeutralStroke1,
          },
          ".MuiPaginationItem-root:hover": {
            borderColor: teamsDarkTheme.colorBrandStroke1,
          },
          ".Mui-selected": {
            opacity: 0.5,
          },
        }}
      />
    </div>
  );
}

export default React.memo(SearchCollectionsComponent);
