import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import { Tag } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";
import { BsTagFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    boxSizing: "border-box",
    ...shorthands.gap("7px"),
  },
  btn: {
    // ...shorthands.margin("5px 0px"),
  },
});

const TagsComponent = ({ thing }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const finalTags = useMemo(() => {
    return thing?.goFinal_tags && thing.goFinal_tags.length
      ? thing.goFinal_tags.slice(0, 20)
      : [];
  }, [thing]);

  return (
    <div
      className={styles.main}
      style={{
        justifyContent: finalTags.length < 4 ? "flex-start" : "space-around",
      }}
    >
      {finalTags.map((tag, index) => {
        const handleClick = () => {
          navigate(`/models?searchText=tag:${tag}`);
        };
        return (
          <div key={index}>
            <Button
              onClick={handleClick}
              className={styles.btn}
              icon={<BsTagFill size={15} />}
            >
              {tag}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TagsComponent);
