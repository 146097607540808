import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Image,
  Text,
  ToggleButton,
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Checkbox,
  Spinner,
} from "@fluentui/react-components";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCollectionThing,
  setCollectionThings,
  setShowAddToCollectionModal,
  setShowCreateCollectionModal,
} from "../../store/items/things";
import { Add } from "@mui/icons-material";
import { MdAdd, MdCollectionsBookmark } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";
import { FaCube } from "react-icons/fa";
import {
  addDoc,
  deleteDoc,
  doc,
  collection as fbColection,
} from "firebase/firestore";
import { db } from "../../hooks/useFirebase";

const useStyles = makeStyles({
  collectionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...shorthands.gap("5px"),
    width: "calc(50% - 5px)",
    minWidth: "calc(50% - 5px)",
    maxWidth: "calc(50% - 5px)",
    height: "100px",
    ...shorthands.padding("10px"),
    boxSizing: "border-box",
    cursor: "pointer",
    ...shorthands.borderRadius("5px"),
    ...shorthands.border("1px", "solid", "transparent"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,

    ":hover": {
      ...shorthands.border("1px", "solid", teamsDarkTheme.colorBrandStroke1),
    },
    ":active": {
      backgroundColor: teamsDarkTheme.colorNeutralBackground2,
    },
  },
  cImage: {
    minWidth: "50px",
    minHeight: "50px",
    width: "50px",
    height: "50px",
    ...shorthands.borderRadius("3px"),
    "@media screen and (max-width: 850px)": {
      display: "none",
    },
  },
  cTitle: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "110px",
    wordBreak: "break-word",
    userSelect: "none",
  },
});

const CollectionItem = React.memo(({ collection, selectedModel }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const userCollections = useSelector((state) => state.things.collections);
  const user = useSelector((state) => state.user.value);
  const userCollectionThings = useSelector(
    (state) => state.things.collectionThings
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const numItems = useMemo(() => {
    return userCollectionThings.filter(
      (thing) => thing.collectionId === collection.uid
    ).length;
  }, [userCollectionThings, collection]);

  const previewSource = useMemo(() => {
    const collectionThings = userCollectionThings.filter(
      (thing) => thing.collectionId === collection.uid
    );

    let finalSrc;
    if (collectionThings.length > 0) {
      finalSrc = collectionThings[0].goFinal_preview_image;
    }

    if (finalSrc) {
      return finalSrc;
    } else {
      return "";
    }
  }, [userCollectionThings, collection]);

  const isInCollection = useMemo(() => {
    return userCollectionThings.filter(
      (thing) =>
        thing?.collectionId === collection?.uid &&
        thing?.goFinal_shareId === selectedModel?.goFinal_shareId &&
        thing?.goFinal_agent === selectedModel?.goFinal_agent
    )[0];
  }, [userCollectionThings, collection, selectedModel]);

  const handleToggleInCollection = useCallback(
    async (val) => {
      if (!selectedModel || isLoading) return;
      setIsLoading(true);
      const collectionRef = fbColection(db, "CollectionThings");
      if (isInCollection) {
        // Delete the document
        const docRef = doc(db, "CollectionThings", isInCollection.uid);
        await deleteDoc(docRef)
          .then(() => {
            dispatch(removeCollectionThing(isInCollection));
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      } else {
        const likeObj = {
          ...selectedModel,
          userId: user?.uid,
          supplier: selectedModel?.goFinal_agent,
          collectionId: collection?.uid,
          date_saved_3dgo: new Date(),
        };
        likeObj.uid && delete likeObj.uid;
        await addDoc(collectionRef, likeObj)
          .then((docRef) => {
            dispatch(
              setCollectionThings([
                { ...likeObj, uid: docRef.id },
                ...userCollectionThings,
              ])
            );
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
      }
      setIsLoading(false);
    },
    [
      selectedModel,
      isLoading,
      collection,
      isInCollection,
      dispatch,
      user?.uid,
      userCollectionThings,
    ]
  );

  return (
    <div className={styles.collectionItem} onClick={handleToggleInCollection}>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {isLoading ? (
          <Spinner
            size="tiny"
            appearance="inverted"
            style={{
              padding: "0px 6px",
            }}
          />
        ) : (
          <Checkbox checked={isInCollection} />
        )}
        {previewSource ? (
          <Image src={previewSource} className={styles.cImage} fit="cover" />
        ) : (
          <div
            className={styles.cImage}
            style={{
              backgroundColor: teamsDarkTheme.colorNeutralBackground3,
            }}
          />
        )}
        <Text weight="bold" className={styles.cTitle}>
          {collection.title}
        </Text>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Text>{numItems}</Text>
        <FaCube />
      </div>
    </div>
  );
});

const AddToCollectionModal = () => {
  const dispatch = useDispatch();
  const selectedModel = useSelector(
    (state) => state.things.showAddToCollectionModal
  );

  const userCollections = useSelector((state) => state.things.collections);

  const setOpen = (val) => {
    dispatch(setShowAddToCollectionModal(false));
  };

  const handleCreateNewCollection = useCallback(() => {
    dispatch(setShowCreateCollectionModal(true));
  }, [dispatch]);

  return (
    <Dialog
      open={selectedModel}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <DialogSurface
        style={{
          backgroundColor: teamsDarkTheme.colorNeutralBackground3,
        }}
      >
        <DialogBody>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              borderRadius: "5px",
              padding: "10px 10px",
              boxShadow: teamsDarkTheme.shadow2,
              backgroundColor: teamsDarkTheme.colorNeutralBackground1,
              flexShrink: 1,
            }}
          >
            <MdCollectionsBookmark />
            <Text
              style={{
                fontSize: "20px",
              }}
              weight="bold"
            >
              Add To Collections
            </Text>
          </DialogTitle>
          <DialogContent>
            {userCollections.length ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  height: "300px",
                  maxHeight: "300px",
                  overflowY: "auto",
                  justifyContent: "space-evenly",
                  padding: "5px",
                }}
              >
                {userCollections.map((c) => {
                  return (
                    <CollectionItem
                      collection={c}
                      selectedModel={selectedModel}
                    />
                  );
                })}
              </div>
            ) : (
              "No collections yet... try creating a new one 😮"
            )}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              icon={<BsPencilFill size={15} />}
              onClick={handleCreateNewCollection}
              style={{
                display: "flex",
                width: "max-content",
              }}
            >
              Create New Collection
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Done</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default React.memo(AddToCollectionModal);
