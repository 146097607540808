import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  mergeClasses,
  Title3,
  Switch,
  Text,
  Skeleton,
  SkeletonItem,
  Image,
  ToggleButton,
  Button,
  Title1,
  Title2,
  Divider,
  Spinner,
} from "@fluentui/react-components";
import { useMotion } from "@fluentui/react-motion-preview";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "./styles.css";

import createApiAgent from "../../utils/api-agent";
import moment from "moment";
import {
  Person20Regular,
  ThumbLike20Regular,
  Collections20Regular,
  Calendar20Regular,
  ArrowDownload20Filled,
  Eye20Regular,
  Link20Regular,
  Bookmark20Regular,
  Bookmark20Filled,
  ChevronLeft20Filled,
  ChevronRight20Filled,
} from "@fluentui/react-icons";
import { setShowModal, setShowPaywall } from "../../store/items/user";
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../hooks/useFirebase";
import { addLike, removeLike } from "../../store/items/likes";
import { StlViewer } from "react-stl-viewer";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import ThingCardPreview from "../../Components/ThingCardPreview";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { modelNormalizer } from "../../utils/normalizers";
import { BarLoader } from "react-spinners";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import RelatedModels from "./RelatedModels";
import ModelComments from "./ModelComments";
import ModelDescription from "./ModelDescription";
import { useWindowSize } from "@uidotdev/usehooks";
import TagsComponent from "./TagsComponent";
import { FaCube } from "react-icons/fa";
import { MdCollectionsBookmark } from "react-icons/md";
import { setShowAddToCollectionModal } from "../../store/items/things";
import { Helmet } from "react-helmet-async";
import ImageViewer from "react-simple-image-viewer";

// const proxyUrl =
//   "http://127.0.0.1:5001/thingiverse-explore/us-central1/proxyImage?agent=printables&url="; //DEV
const proxyUrl =
  "https://us-central1-thingiverse-explore.cloudfunctions.net/proxyImage?agent=printables&url=";

function sanitizeFilename(filename) {
  // Remove illegal characters
  filename = filename.replace(/[<>:"\/\\|?*]+/g, "");

  // Replace spaces with underscores
  filename = filename.replace(/\s+/g, "_");

  // Trim leading/trailing spaces and dots
  filename = filename.trim().replace(/^[.]+|[.]+$/g, "");

  // Limit length
  const maxLength = 255;
  filename =
    filename.length > maxLength ? filename.substring(0, maxLength) : filename;

  // Provide a default name if empty
  if (!filename) {
    filename = "well_this_is_interesting";
  }

  return filename;
}

const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en&gl=US";
const APP_STORE_URL =
  "https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942";

const useStyles = makeStyles({
  megaWrap: {
    overflowY: "scroll",
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
  },
  crunchyyy: {
    maxWidth: "1200px",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    // ...shorthands.flex(1),
    justifyContent: "space-between",
    ...shorthands.gap("20px"),
    ...shorthands.borderRadius("5px"),
    "@media screen and (max-width: 850px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  slider: {
    width: "45vw",
    maxWidth: "calc(600px - 40px)",
    maxHeight: "50vh",
    height: "50vh",
    backgroundColor: "black",
    ...shorthands.borderRadius("5px"),
    "@media screen and (max-width: 850px)": {
      width: "100%",
      height: "400px",
      maxHeight: "400px",
    },
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
    flexGrow: 1,
    maxWidth: "600px",
    width: "calc(100vw / 2 - 40px)",
    ...shorthands.flex(1),
    ...shorthands.gap("20px"),
    ...shorthands.padding("20px", "0px"),
    height: "calc(100% - 40px)",
    "@media screen and (max-width: 850px)": {
      ...shorthands.padding("20px"),
      // overflowY: "scroll",
      maxHeight: "100%",
      width: "auto",
      // width: "100%",
      minHeight: "100%",
      maxWidth: "calc(100vw - 50px)",
    },
  },
  swiperWrap: {
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("10px"),
    display: "flex",
    boxSizing: "border-box",
    maxWidth: "600px",
    flexDirection: "column",
    height: "100%",
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    "@media screen and (max-width: 850px)": {
      position: "relative",
      top: "auto",
      maxWidth: "calc(100vw - 20px)",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  innerSwiper: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
    position: "sticky",
    top: "20px",
  },
  skeletonImage: {
    maxWidth: "calc(600px - 40px)",
    width: "45vw",
    maxHeight: "calc(50vh + 110px)",
    height: "calc(50vh + 110px)",
    backgroundColor: "black",
    ...shorthands.borderRadius("5px"),
    "@media screen and (max-width: 850px)": {
      maxWidth: "100vw",
      width: "100%",
      height: "510px",
      maxHeight: "510px",
    },
  },
  rcS: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    ...shorthands.gap("25px"),
    ...shorthands.margin("-10px", "0px"),
    "@media screen and (max-width: 850px)": {
      justifyContent: "space-around",
      ...shorthands.gap("0px"),
    },
  },
  rsSI: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.padding("5px", "15px"),
    ...shorthands.borderRadius("5px"),
    ...shorthands.gap("5px"),
    "@media screen and (max-width: 850px)": {
      alignItems: "flex-start",
    },
  },
  agentIconWrap: {
    ...shorthands.padding("5px"),
  },
  titleWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("10px"),
    minWidth: "100%",
    boxShadow: teamsDarkTheme.shadow2,
    backgroundColor: teamsDarkTheme.colorNeutralBackground1,
    ...shorthands.padding("5px", "10px"),
    ...shorthands.borderRadius("5px"),
    boxSizing: "border-box",
  },
  switchH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    ...shorthands.gap("5px"),
    ...shorthands.padding("5px"),
  },
  previewToggleButton: {
    flexGrow: 1,
    ...shorthands.padding("20px"),
    "@media screen and (max-width: 850px)": {
      ...shorthands.padding("10px"),
    },
  },
  belowContentMainH: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  belowContentSubV: {
    display: "flex",
    flexDirection: "column",
    // flexDirection: "row",
    alignItems: "center",
    width: "100%",
    ...shorthands.gap("15px"),
    "@media screen and (max-width: 850px)": {
      justifyContent: "center",
    },
  },
  actionButton: {
    // minWidth: "",
  },
  loaderWrap: {
    display: "flex",
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  actionBtnH: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    ...shorthands.gap("15px"),
  },
  bigActionBtn: {
    ...shorthands.flex(3),
    ...shorthands.padding("15px"),
  },
  toggleBtn: {
    minWidth: "75px",
  },
  smallActionBtn: {
    ...shorthands.flex(1),
    ...shorthands.padding("15px"),
  },
  miniSlider: {
    width: "45vw",
    maxWidth: "calc(600px - 40px)",
    height: "100px",
    "@media screen and (max-width: 850px)": {
      height: "100px",
      width: "100%",
    },
    "> * > *": {
      cursor: "pointer",
      boxSizing: "border-box",
      ...shorthands.border("solid", "2px", "transparent"),
      ...shorthands.borderRadius("3px"),
    },
    "> * > *:hover": {
      ...shorthands.border("solid", "2px", teamsDarkTheme.colorBrandStroke1),
    },
    "> .swiper-button-prev, .swiper-button-next": {
      display: "flex",
      height: "100%",
      boxSizing: "border-box",
      justifyContent: "center",
      top: 0,
      ...shorthands.padding("15px"),
      ...shorthands.margin(0),
    },
    "> .swiper-button-prev:hover, .swiper-button-next:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
});

const ModelScreen = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { search, state } = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const location = useLocation();

  const searchParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );
  const [selectedThing, setSelectedThing] = useState(state?.thing);
  const apiAgent = useMemo(() => {
    return createApiAgent(searchParams.agent);
  }, [searchParams]);

  const likes = useSelector((state) => state.likes.likesArr);
  const user = useSelector((state) => state.user.value);
  const revenueCat = useSelector((state) => state.user.revenueCat);

  const [LoadingThing, setLoadingThing] = useState(false);
  const [LoadingImages, setLoadingImages] = useState(false);
  const [thingimages, setThingImages] = useState([]);
  const [thingDetails, setThingDetails] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);

  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [showFullPreview, setShowFullPreview] = useState(false);

  const isPrintables = selectedThing?.goFinal_agent === "printables";

  const canNavProfile = useMemo(
    () => Boolean(selectedThing?.goFinal_creator?.identifier),
    [selectedThing]
  );

  const [isImgPreview, setIsImgPreview] = useState(true);

  const isSaved = useMemo(() => {
    return likes.filter((like) => {
      if (!selectedThing) return false;
      const newTest =
        like.goFinal_id === selectedThing?.goFinal_id &&
        like.supplier === selectedThing?.goFinal_agent;
      const oldTest =
        like.id &&
        selectedThing?.id &&
        String(like.id) === String(selectedThing?.id) &&
        like.supplier === selectedThing?.goFinal_agent;
      return newTest || oldTest;
    })[0];
  }, [likes, selectedThing]);

  const [isSavedState, setIsSavedState] = useState(Boolean(isSaved));

  React.useEffect(() => {
    setIsSavedState(Boolean(isSaved));
  }, [isSaved]);

  useEffect(() => {
    const modalSetup = async () => {
      let initialThing = state?.thing;
      if (!initialThing) {
        const tempAgent = createApiAgent("home");
        const model = await tempAgent.singleModel({
          id: searchParams.id,
          agent: searchParams.agent,
        });

        const normalThing = modelNormalizer(model, searchParams.agent);
        setSelectedThing(normalThing);
        initialThing = normalThing;
      } else {
        setSelectedThing(initialThing);
      }
      if (
        initialThing.goFinal_agent === "thingiverse" ||
        initialThing.goFinal_agent === "printables" ||
        initialThing.goFinal_agent === "makerworld"
      ) {
        setLoadingThing(true);
        setLoadingImages(true);
        const extraInfo = await apiAgent.getExtraDetails({
          id: searchParams.id,
        });
        setThingDetails(extraInfo);
        setThingImages(extraInfo.images);
        setLoadingThing(false);
        setLoadingImages(false);
      } else {
        setThingImages(initialThing.goFinal_images);
        setLoadingImages(false);
      }
    };
    setThingDetails({});
    setLoadingImages(true);
    setThingImages([]);
    modalSetup();
  }, [apiAgent, searchParams, state]);

  const generateCount = useCallback((num) => {
    if (!num) return 0;
    else {
      return Number(num).toLocaleString();
    }
  }, []);

  const handleNoUser = useCallback(() => {
    dispatch(setShowModal(true));
  }, [dispatch]);

  const handleToggleLike = useCallback(async () => {
    if (!user) {
      if (isMobile) {
        const redirectUrl = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
        window.open(redirectUrl, "_blank");
        return;
      }
      handleNoUser();
      return;
    }

    const originalState = isSavedState;
    setIsSavedState(!originalState);

    try {
      if (isSaved) {
        await deleteDoc(doc(db, "Likes", isSaved.uid)).then((resp) => {
          dispatch(removeLike(isSaved));
        });
      } else {
        const likeObj = {
          ...selectedThing,
          userId: user.uid,
          supplier: selectedThing?.goFinal_agent,
          date_saved_3dgo: new Date(),
        };
        likeObj.uid && delete likeObj.uid;
        await addDoc(collection(db, "Likes"), likeObj).then((resp) => {
          dispatch(addLike({ ...likeObj, uid: resp.id }));
        });
      }
    } catch (e) {
      setIsSavedState(originalState);
    }
  }, [dispatch, handleNoUser, isSaved, isSavedState, selectedThing, user]);

  const handleURL = useCallback(() => {
    window.open(selectedThing?.goFinal_url, "_blank", "noopener,noreferrer");
  }, [selectedThing]);

  const handleDownload = useCallback(async () => {
    const agent = selectedThing?.goFinal_agent;
    const canDownload = agent === "thingiverse";
    const thingiverseUrl = selectedThing?.goFinal_url + "/zip";
    setIsDownloading(true);
    if (canDownload) {
      if (agent === "thingiverse") {
        // const proxyUrl =
        //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/corsProxyBlob?url="; //DEV
        const proxyUrl =
          "https://us-central1-thingiverse-explore.cloudfunctions.net/corsProxyBlob?url=";
        const response = await fetch(proxyUrl + thingiverseUrl);
        const blob = await response.blob();
        const safeFilename =
          sanitizeFilename(selectedThing?.goFinal_name) + ".zip";
        let tempLink = document.createElement("a");
        tempLink.href = URL.createObjectURL(blob);
        tempLink.setAttribute("download", safeFilename);
        tempLink.click();
      } else if (agent === "makerworld") {
        window.open(thingDetails?.download_url, "_blank");
      }
    } else {
      window.open(selectedThing?.goFinal_url, "_blank", "noopener,noreferrer");
    }
    setIsDownloading(false);
  }, [selectedThing, thingDetails]);

  const handlePreviewToggle = useCallback(
    (val) => {
      if (
        selectedThing?.goFinal_agent === "printables" ||
        selectedThing?.goFinal_agent === "thingiverse" ||
        selectedThing?.goFinal_agent === "makerworld"
      ) {
        setIsImgPreview(val);
      }
    },
    [selectedThing?.goFinal_agent]
  );

  const handleProviderProfileNav = useCallback(() => {
    navigate(
      `/provider_profile?agent=${selectedThing?.goFinal_agent}&id=${selectedThing?.goFinal_creator?.identifier}`,
      { state: { profile: selectedThing?.goFinal_creator } }
    );
  }, [navigate, selectedThing]);

  const previewWidth = useMemo(() => {
    return windowSize.width <= 850
      ? windowSize.width - 10
      : Math.min(windowSize.width / 2 - 40, 560);
  }, [windowSize.width]);

  const handleAddToCollection = useCallback(() => {
    if (!user) {
      dispatch(setShowModal(true));
      return;
    }
    dispatch(setShowAddToCollectionModal(selectedThing));
  }, [dispatch, selectedThing, user]);

  const userImage = useMemo(() => {
    let url = selectedThing?.goFinal_creator?.thumbnail;
    return url;
  }, [selectedThing]);

  const metaAgent = useMemo(() => {
    try {
      if (searchParams?.agent) {
        const str = searchParams.agent;
        return str[0].toUpperCase() + str.slice(1);
      } else {
        return "3D GO";
      }
    } catch (e) {
      return "3D GO";
    }
  }, [searchParams?.agent]);

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const DownloadButton = () => {
    // Check if selectedThing.goFinal_agent is 'thingiverse'
    if (selectedThing?.goFinal_agent === "thingiverse") {
      return (
        <Button
          icon={<ArrowDownload20Filled />}
          onClick={handleDownload}
          appearance="primary"
          className={styles.bigActionBtn}
        >
          {isDownloading ? "Downloading..." : "Download Files"}
        </Button>
      );
    } else {
      return (
        <a
          href={selectedThing?.goFinal_url}
          target="_blank"
          rel="noreferrer"
          style={{
            flex: 3,
          }}
        >
          <Button
            icon={<ArrowDownload20Filled />}
            style={{
              width: "100%",
              height: "100%",
              padding: "15px",
            }}
            appearance="primary"
          >
            Download Files
          </Button>
        </a>
      );
    }
  };

  if (!selectedThing)
    return (
      <div className={styles.loaderWrap}>
        <BarLoader color="white" />
      </div>
    );
  else
    return (
      <>
        <Helmet>
          <title>{metaAgent} - 3D Model Details</title>
          <meta
            name="description"
            content={`${metaAgent} - ${
              selectedThing?.goFinal_name ?? ""
            } 3D Model Details, 3D Previewing, Collect & Save Model.`}
          />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div className={styles.megaWrap}>
          <div className={styles.crunchyyy}>
            <div className={styles.main}>
              <div className={styles.swiperWrap}>
                <div className={styles.innerSwiper}>
                  {isImgPreview ? (
                    <>
                      {LoadingImages ? (
                        <Skeleton className={styles.skeletonImage}>
                          <SkeletonItem className={styles.skeletonImage} />
                        </Skeleton>
                      ) : (
                        <>
                          <Swiper
                            navigation={true}
                            modules={[Navigation, Thumbs]}
                            className={styles.slider}
                            loop={true}
                            thumbs={{
                              swiper:
                                thumbsSwiper && !thumbsSwiper.destroyed
                                  ? thumbsSwiper
                                  : null,
                            }}
                            style={{
                              "--swiper-pagination-color":
                                teamsDarkTheme.colorBrandStroke1,
                              "--swiper-navigation-color":
                                teamsDarkTheme.colorBrandStroke1,
                              "--swiper-navigation-sides-offset": "0px",
                              "--swiper-navigation-size": "30px",
                            }}
                          >
                            {thingimages &&
                              thingimages.length &&
                              thingimages.map((image, i) => {
                                return (
                                  <SwiperSlide
                                    key={i}
                                    onClick={() => {
                                      setShowFullPreview({
                                        images: thingimages.map((img) => {
                                          return isPrintables
                                            ? proxyUrl + img.url
                                            : img.url;
                                        }),
                                        clicked: i,
                                      });
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Image
                                      src={
                                        isPrintables
                                          ? proxyUrl + image.url
                                          : image.url
                                      }
                                      fit="contain"
                                      loading="eager"
                                      decoding="async"
                                    />
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                          <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={4}
                            watchSlidesProgress={true}
                            modules={[Navigation, Thumbs, Pagination, FreeMode]}
                            className={styles.miniSlider}
                            slidesPerGroup={4}
                            slidesPerGroupSkip={4}
                            navigation={true}
                            style={{
                              "--swiper-navigation-sides-offset": "0px",
                              "--swiper-navigation-size": "15px",
                              "--swiper-navigation-color": "white",
                            }}
                          >
                            {thingimages &&
                              thingimages.length &&
                              thingimages.map((image, i) => {
                                return (
                                  <SwiperSlide key={i}>
                                    <Image
                                      // src={
                                      //   searchParams.agent === "printables"
                                      //     ? proxyUrl + image.url
                                      //     : image.url
                                      // }
                                      src={
                                        isPrintables
                                          ? proxyUrl + image.url
                                          : image.url
                                      }
                                      fit="cover"
                                      loading="eager"
                                      decoding="async"
                                    />
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                        </>
                      )}
                    </>
                  ) : (
                    <ThingCardPreview
                      width={previewWidth}
                      height={window.innerWidth <= 850 ? 400 : 600}
                      selectedThing={selectedThing}
                    />
                  )}
                  <div className={styles.belowContentMainH}>
                    <div className={styles.switchH}>
                      <Button
                        onClick={() => handlePreviewToggle(true)}
                        appearance={isImgPreview ? "outline" : "primary"}
                        className={styles.previewToggleButton}
                        icon={<ChevronLeft20Filled />}
                      >
                        View Images
                      </Button>
                      <Button
                        onClick={() => handlePreviewToggle(false)}
                        appearance={!isImgPreview ? "outline" : "primary"}
                        className={styles.previewToggleButton}
                        icon={<ChevronRight20Filled />}
                        iconPosition="after"
                      >
                        3D Preview
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.titleWrap}>
                  <FaCube size={25} />
                  <Title2>{selectedThing?.goFinal_name}</Title2>
                </div>
                {/* general info */}
                <div className={styles.rcS}>
                  <div className={styles.rsSI}>
                    <Calendar20Regular />
                    <Text>
                      {moment(selectedThing?.goFinal_created_at).format(
                        "MMM DD YYYY"
                      )}
                    </Text>
                  </div>
                  <div className={styles.rsSI}>
                    <ThumbLike20Regular />
                    <Text>
                      {generateCount(selectedThing?.goFinal_like_count)} Like(s)
                    </Text>
                  </div>
                  <div className={styles.rsSI}>
                    {selectedThing?.goFinal_agent === "thingiverse" ? (
                      <>
                        <Collections20Regular />
                        <Text>
                          {generateCount(selectedThing?.goFinal_collect_count)}{" "}
                          Collected
                        </Text>
                      </>
                    ) : selectedThing?.goFinal_download_count ? (
                      <>
                        <ArrowDownload20Filled />
                        <Text>
                          {generateCount(selectedThing?.goFinal_download_count)}{" "}
                          Download(s)
                        </Text>
                      </>
                    ) : (
                      <>
                        <Eye20Regular />
                        <Text>
                          {generateCount(selectedThing?.goFinal_view_count)}{" "}
                          Views
                        </Text>
                      </>
                    )}
                  </div>
                </div>
                {/* action buttons */}
                <div className={styles.belowContentSubV}>
                  <div className={styles.actionBtnH}>
                    <Button
                      // icon={<Person20Regular />}
                      icon={
                        <Image
                          src={userImage}
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "100%",
                          }}
                        />
                      }
                      onClick={handleProviderProfileNav}
                      appearance={canNavProfile ? "primary" : "outline"}
                      className={styles.bigActionBtn}
                      disabled={!canNavProfile}
                    >
                      {selectedThing?.goFinal_creator.name}
                      <ChevronRight20Filled />
                    </Button>
                    <Button
                      onClick={handleAddToCollection}
                      icon={<MdCollectionsBookmark />}
                      className={styles.toggleBtn}
                      appearance="primary"
                    />
                    <ToggleButton
                      className={styles.toggleBtn}
                      checked={isSavedState}
                      onClick={handleToggleLike}
                      icon={
                        isSavedState ? (
                          <Bookmark20Filled />
                        ) : (
                          <Bookmark20Regular />
                        )
                      }
                      appearance="primary"
                    >
                      {/* {isSavedState ? "Unsave" : "Save"} Model */}
                    </ToggleButton>
                  </div>
                  <div className={styles.actionBtnH}>
                    <Button
                      icon={
                        <AgentIcon
                          agent={selectedThing?.goFinal_agent}
                          styles={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "7px",
                            margin: "15px",
                          }}
                        />
                      }
                      onClick={handleURL}
                      appearance="primary"
                    >
                      Visit
                    </Button>
                    <DownloadButton />
                  </div>
                  {/* Tags */}
                  <TagsComponent thing={selectedThing} />
                </div>
                {/* Descriptions */}
                <ModelDescription
                  selectedThing={selectedThing}
                  LoadingThing={LoadingThing}
                  thingDetails={thingDetails}
                />
                {/* Related Models */}
                <RelatedModels params={searchParams} thing={selectedThing} />
                {/* Model Comments */}
                <ModelComments params={searchParams} thing={selectedThing} />
              </div>
            </div>
          </div>
        </div>
        {showFullPreview && (
          <div
            style={{
              zIndex: 1000,
            }}
          >
            <ImageViewer
              src={showFullPreview.images || []}
              currentIndex={showFullPreview.clicked || 0}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={() => setShowFullPreview(false)}
            />
          </div>
        )}
      </>
    );
};

export default React.memo(ModelScreen);
