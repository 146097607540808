import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
} from "@fluentui/react-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SearchCollectionsFilterSort from "./SearchCollectionsFilterSort";
import SearchCollectionsComponent from "./SearchCollectionsComponent";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    height: "100%",
    overflowY: "scroll",
    alignItems: "center",
    boxSizing: "border-box",
  },
  crunchWrapSupreme: {
    display: "flex",
    ...shorthands.flex(1),
    flexDirection: "column",
    width: "100%",
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  mainBody: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.flex(1),
    alignItems: "flex-start",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    "@media (max-width: 850px)": {
      flexDirection: "column",
    },
  },
});

function SearchCollections() {
  const styles = useStyles();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const urlSearchText = useMemo(
    () => queryParams.get("searchText"),
    [queryParams]
  );
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sort, setSort] = React.useState("newest");
  const [searchText, setSearchText] = useState(
    urlSearchText ? urlSearchText : ""
  );

  const canonicalUrl = useMemo(() => {
    const baseUrl = window.location.pathname;
    return baseUrl + (location.search ? location.search : "");
  }, [location]);

  const [didSearch, setDidSearch] = useState(urlSearchText ? true : false);
  const [cursor, setCursor] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const props = useMemo(() => {
    return {
      page,
      setPage,
      sort,
      setSort,
      searchText,
      setSearchText,
      didSearch,
      setDidSearch,
      cursor,
      setCursor,
    };
  }, [cursor, didSearch, page, searchText, sort]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (sort !== "newest") {
      newSearchParams.set("sort", sort);
    } else {
      newSearchParams.delete("sort");
    }
    if (page !== 1) {
      newSearchParams.set("page", page.toString());
    } else {
      newSearchParams.delete("page");
    }
    if (searchText !== "") {
      newSearchParams.set("searchText", searchText);
    } else {
      newSearchParams.delete("searchText");
    }

    setSearchParams(newSearchParams);
  }, [sort, page, searchText, setSearchParams]);

  return (
    <>
      <Helmet>
        <title>Search 3D Model Collections</title>
        <meta
          name="description"
          content="Search 3D Model Collections Created On 3D GO"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div
        className={styles.root}
        style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
        id="modelsPageRef"
      >
        <div className={styles.crunchWrapSupreme}>
          <div className={styles.mainBody}>
            <SearchCollectionsFilterSort {...props} />
            <SearchCollectionsComponent {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(SearchCollections);
