import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Text,
  Title1,
  makeStyles,
  shorthands,
  teamsDarkTheme,
} from "@fluentui/react-components";
import { BarLoader } from "react-spinners";
import Processor from "./Processor";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  mainWrap: {
    display: "flex",
    flexGrow: 1,
  },
  mainCheckout: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: "5px",
  },
  contentWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: teamsDarkTheme.colorBrandStroke2,
    width: "100%",
    color: "white",
    ...shorthands.borderRadius("5px"),
    ...shorthands.gap("10px"),
    ...shorthands.padding("5px"),
    boxShadow: teamsDarkTheme.shadow2,
  },
  loader: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  productString: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.padding("3px"),
    ...shorthands.borderRadius("5px"),
    backgroundColor: teamsDarkTheme.colorNeutralBackground6,
    color: "white",
    width: "100%",
  },
});

//dev STRIPE TEST MODE SWITCH
// const stripePromise = loadStripe(
//   "pk_test_51O9xvtK2VAYUgcTBTZ5zgCKxcQJoAzlP1Sh2EkIEGNXeT3AF6wRcYAhiofwPwDpmkgkC495tAvY889NIxKVegU1h001mrmT5i3"
// );

//prod
const stripePromise = loadStripe(
  "pk_live_51O9xvtK2VAYUgcTBiOx04JrSkKxhZzu0kWvcBtQHZWCHls57FAwxOt7gLjb8EjLpQpm0cqFPPhNB59d54KPFWbmJ00VkjY0pnb"
);

const Checkout = ({ setShowCheckout }) => {
  const styles = useStyles();
  const [clientSecret, setClientSecret] = useState("");
  const [purchaseData, setPurchaseData] = useState({});
  const user = useSelector((state) => state.user.value);
  const isAnnual = true;

  const createPaymentIntent = async () => {
    if (clientSecret) return;
    // const devUrl =
    //   "http://127.0.0.1:5001/thingiverse-explore/us-central1/createPaymentIntent";
    const prodUrl =
      "https://us-central1-thingiverse-explore.cloudfunctions.net/createPaymentIntent";

    await fetch(prodUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user: user, isAnnual: isAnnual }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPurchaseData(data);
        setClientSecret(data.clientSecret);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    createPaymentIntent();
  }, [isAnnual]);

  const appearance = {
    theme: "minimalist",
    variables: {
      colorText: "#fff",
      colorBackground: teamsDarkTheme.colorNeutralBackground3,
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className={styles.mainWrap}>
      {clientSecret ? (
        <div className={styles.mainCheckout}>
          {/* <div className={styles.contentWrap}>
            <Title1>Happy Printing 🥳</Title1>
            <Text>Thank you for your support & enjoy!</Text>
          </div> */}
          {/* <div className={styles.productString}>
            <Text>1x {productString} PRO subscription</Text>
          </div> */}
          <Elements stripe={stripePromise} options={options}>
            <Processor
              clientSecret={clientSecret}
              purchaseData={purchaseData}
              setShowCheckout={setShowCheckout}
            />
          </Elements>
        </div>
      ) : (
        <div className={styles.loader}>
          <BarLoader color="white" />
        </div>
      )}
    </div>
  );
};

export default React.memo(Checkout);
